import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './common/app-error-handler';
import { NotfoundComponent } from './notfound/notfound.component';
import { AuthService } from './services/auth.service';

import { LogoutComponent } from './logout/logout.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { AuthGuard } from './services/auth-guard.service';

// import { DoctorRegistrationFormComponent } from './signup/doctor-registration-form/doctor-registration-form.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { EmailEncodingPipe } from './custom-pipes/email-encoding.pipe';
import { ReplaceWithStarPipe } from './custom-pipes/replace-with-star.pipe';
import { FooterComponent } from './footer/footer.component';
import { AdminHeaderComponent } from './header/admin-header/admin-header.component';
import { DoctorHeaderComponent } from './header/doctor-header/doctor-header.component';
import { PatientHeaderComponent } from './header/patient-header/patient-header.component';
import { PublicHeaderComponent } from './header/public-header/public-header.component';
import { UserDisplayPipe } from './help/user-display.pipe';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { DoctorLayoutComponent } from './layouts/doctor-layout/doctor-layout.component';
import { PatientLayoutComponent } from './layouts/patient-layout/patient-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ZoomLayoutComponent } from './layouts/zoom-layout/zoom-layout.component';
import { AdminNavbarComponent } from './menu/admin-navbar/admin-navbar.component';
import { DoctorNavbarComponent } from './menu/doctor-navbar/doctor-navbar.component';
import { PatientNavbarComponent } from './menu/patient-navbar/patient-navbar.component';
import { AvailabilityService } from './services/availability.service';
import { UserService } from './services/user.service';

import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { BannerBreadcrumbComponent } from './public/banner-breadcrumb/banner-breadcrumb.component';

import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConnectFormComponent } from './connect-form/connect-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GoogleUserRegFormComponent } from './signup/google-user-reg-form/google-user-reg-form.component';
import { AppointmentStoreModule } from './store/AppointmentStore/store.modules';
import { UpdatePasswordComponent } from './update-password/update-password.component';

// Import the library

// Needs to import the BrowserAnimationsModule
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    LogoutComponent,
    NoAccessComponent,
    // DoctorRegistrationFormComponent,
    AdminLayoutComponent,
    PatientLayoutComponent,
    DoctorLayoutComponent,
    DoctorHeaderComponent,
    FooterComponent,
    PublicLayoutComponent,
    PatientHeaderComponent,
    AdminHeaderComponent,
    PublicHeaderComponent,
    PatientNavbarComponent,
    DoctorNavbarComponent,
    ReplaceWithStarPipe,
    EmailEncodingPipe,
    ZoomLayoutComponent,
    AdminNavbarComponent,
    UserDisplayPipe,
    AdminLoginComponent,
    BannerBreadcrumbComponent,
    GoogleUserRegFormComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    ConnectFormComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    NgxCaptchaModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    BrowserModule,
    MatInputModule,
    StoreModule.forRoot(),
    StoreDevtoolsModule.instrument({}),
    AppointmentStoreModule,

    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      closeButton: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],

  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.appId),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GAPI_CLIENT_ID),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    AuthService,
    AuthGuard,
    UserService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    //{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ReCaptcha2Component,
    AvailabilityService,
    ToastrService,
    DatePipe,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
