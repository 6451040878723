import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AsyncValidators } from 'src/app/helper/user.validator';
import { AuthService } from 'src/app/services/auth.service';
import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdatePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  passwordFlg = true;
  stValue: any = '';
  emailbase64: string = '';
  newPasswordDisabled = false;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.changePasswordForm = this.formBuilder.group({});
    this.route.queryParams.subscribe((params) => {
      this.stValue = params['st'] || '';
      this.emailbase64 = params['email'] || '';
      console.log(this.stValue, '======');
      console.log(this.emailbase64);
      if (this.stValue === '3' || this.stValue === 3) {
        this.newPasswordDisabled = true;
        this.toastr.warning(
          'This link has expired.',
          'Please generate a new password reset link.'
        );
      }
      if (this.stValue === '2' || this.stValue === 2) {
        this.newPasswordDisabled = true;
        this.toastr.error('User Not Found !');
      }
    });
  }

  get new_password() {
    return this.changePasswordForm.get('new_password');
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      new_password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        ),
      ]),
      email: this.emailbase64,
    });
    if (
      this.newPasswordDisabled &&
      this.changePasswordForm.get('new_password')
    ) {
      this.changePasswordForm.get('new_password')!.disable();
    }
  }
  showHidePassword() {
    this.passwordFlg = !this.passwordFlg;
  }
  getPasswordErrorMessage(): string {
    const hasNumber = /\d/.test(this.new_password?.value);
    const hasSpecialChar = /[#?!@$%^&*-]/.test(this.new_password?.value);

    let message = 'Invalid password. ';

    if (!hasNumber) {
      message += 'Password must contain at least one number. ';
    }

    if (!hasSpecialChar) {
      message += 'Password must contain at least one special character.';
    }

    return message.trim();
  }

  changePasword() {
    if (this.changePasswordForm.invalid) {
      if (this.stValue !== 1 || this.stValue !== '1') {
        return;
      }
      return;
    }
    console.log(this.changePasswordForm.value);

    this.spinner.show();
    if (this.stValue === 1 || this.stValue === '1') {
      this.authService.updatePassword(this.changePasswordForm.value).subscribe(
        (data: any) => {
          this.spinner.hide();
          this.toastr.success('', 'Password updated successfully');
          this.changePasswordForm.reset();
          this.router.navigate(['/']);
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error('', error);
          this.changePasswordForm.reset();
        }
      );
    }
  }
}
