<div class="main-wrapper">
    <app-admin-header></app-admin-header>
    <app-admin-navbar></app-admin-navbar>
    <div class="page-wrapper" style="min-height: 600px;">
        <div class="content container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
    
</div>
<div class="sidebar-overlay"></div>

  

