import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AsyncValidators } from '../../helper/user.validator';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-doctor-registration-form',
  templateUrl: './doctor-registration-form.component.html',
  styleUrls: ['./doctor-registration-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorRegistrationFormComponent implements OnInit {
  certificationValid = true;
  maxSizeInBytes: number = 2 * 1024 * 1024; // 2MB
  allowedFileTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/doc',
    'application/docx',
  ];
  @Input()
  activeStepIndex: number = 2;

  @Output() setStepperActiveIndex: EventEmitter<number> = new EventEmitter();

  submitted = false;
  baseUrl: string = environment.baseUrl;
  siteKey: string;
  type: any;
  token: string | undefined;
  theme: any;
  lang: any;
  size: any;
  countryLst: any = [];
  stateLst: any = [];

  genderLst = [
    { gender_id: 'M', gender: 'Male' },
    { gender_id: 'F', gender: 'Female' },
    { gender_id: 'O', gender: 'Other' },
  ];
  physicianLst = [
    { value: 'Y', available: 'Yes' },
    { value: 'N', available: 'No' },
  ];
  familyCounsellingLst = [
    { value: 'Y', available: 'Yes' },
    { value: 'N', available: 'No' },
  ];
  languageLst = [
    { value: 'English', name: 'English' },
    { value: 'Bengali', name: 'Bengali' },
    { value: 'Hindi', name: 'Hindi' },
  ];
  specializationLst = [
    { value: 'Addictions', name: 'Addictions' },
    { value: 'Eating Disorders', name: 'Eating Disorders' },
    { value: 'Gender Identity Issues', name: 'Gender Identity Issues' },
    { value: 'ADHD', name: 'ADHD' },
    { value: 'OCD', name: 'OCD' },
    { value: 'Sexual Orientation Issues', name: 'Sexual Orientation Issues' },
    { value: 'Bipolar Disorder', name: 'Bipolar Disorder' },
    { value: 'PTSD', name: 'PTSD' },
    { value: 'Autism', name: 'Autism' },
    { value: 'Schizophrenia', name: 'Schizophrenia' },
    { value: 'Injury/Illness', name: 'Injury/Illness' },
    { value: 'Sexual Difficulties', name: 'Sexual Difficulties' },
    { value: 'Life Coaching', name: 'Life Coaching' },
    { value: 'Learning Disabalities', name: 'Learning Disabalities' },
    {
      value: 'Postpartum Depression/Anxiety',
      name: 'Postpartum Depression/Anxiety',
    },
    {
      value: 'Marital Difficulties',
      name: 'Marital/Relationship Difficulties',
    },
    { value: 'Grief/Loss', name: 'Grief/Loss' },
    {
      value: 'Borderline Personality Disorder',
      name: 'Borderline Personality Disorder',
    },
  ];

  offerLst = [
    {
      value: 'Cognitive Behavioral Therapy (CBT)',
      name: 'Cognitive Behavioral Therapy (CBT)',
    },
    {
      value: 'Dialectical Behavior Therapy (DBT) Disorders',
      name: 'Dialectical Behavior Therapy (DBT)',
    },
    {
      value: 'Acceptance and Commitment Therapy (ACT)',
      name: 'Acceptance and Commitment Therapy (ACT)',
    },
    {
      value: 'Interpersonal Therapy (IPT)',
      name: 'Interpersonal Therapy (IPT)',
    },
    { value: 'Psychodynamic Therapy', name: 'Psychodynamic Therapy' },
    { value: 'Mindfulness-Based Therapy', name: 'Mindfulness-Based Therapy' },
    { value: 'Family Therapy', name: 'Family Therapy' },
    { value: 'Couples Therapy', name: 'Couples Therapy' },
    {
      value: 'Eye Movement Desensitization and Reprocessing (EMDR) Therapy',
      name: 'Eye Movement Desensitization and Reprocessing (EMDR) Therapy',
    },
    { value: 'Art Therapy', name: 'Art Therapy' },
  ];
  approachLst = [
    {
      value: 'Professional and personal goal setting',
      name: 'Professional and personal goal setting',
    },
    {
      value: 'Time and task management strategies',
      name: 'Time and task management strategies',
    },
    {
      value: 'The impact of thoughts on emotions and behaviours',
      name: 'The impact of thoughts on emotions and behaviours',
    },
    {
      value: 'Trauma and chaos of the past',
      name: 'Trauma and chaos of the past',
    },
    {
      value: 'Moral, spiritual, and religious influences',
      name: 'Moral, spiritual, and religious influences',
    },
    {
      value: 'Mindfulness and emotional acceptance',
      name: 'Mindfulness and emotional acceptance',
    },

    {
      value: 'Practical ways of coping with life',
      name: 'Practical ways of coping with life',
    },
    {
      value: 'Relationship health and life transitions',
      name: 'Relationship health and life transitions',
    },
    {
      value: 'Deep-rooted feelings and impulses',
      name: 'Deep-rooted feelings and impulses',
    },
  ];
  agegroupLst = [
    {
      value: 'Children',
      name: 'Children',
    },
    {
      value: 'Preteen (10-13 years)',
      name: 'Preteen (10-13 years)',
    },
    {
      value: 'Adolescents (15-19 years)',
      name: 'Adolescents (15-19 years)',
    },
    {
      value: 'Adults (20-64 years)',
      name: 'Adults (20-64 years)',
    },
    {
      value: 'Seniors (65+ years)',
      name: 'Seniors (65+ years)',
    },
  ];
  weekdayLst = [
    {
      value: 'day',
      imagesrc: 'assets/images/sun_icon.png',
    },
    {
      value: 'night',
      imagesrc: 'assets/images/moon_icon.png',
    },
  ];
  weekendLst = [
    {
      value: 'day',
      imagesrc: 'assets/images/sun_icon.png',
    },
    {
      value: 'night',
      imagesrc: 'assets/images/moon_icon.png',
    },
  ];
  titleLst = [{ title: 'Mr.' }, { title: 'Ms.' }, { title: 'Dr.' }];

  fileToUpload: any;
  fileToUploadTherapist: File[] = [];
  registerdocForm: UntypedFormGroup;
  newSpecializationForm: UntypedFormGroup;
  newOfferForm: UntypedFormGroup;
  newLanguageForm: UntypedFormGroup;
  invalidForm: boolean;
  errorMessage: string;
  // showCaptcha = true;
  showCustomLanguageInput = false;
  customLanguage = '';
  registrationSuccessed: boolean;
  orgPasswordFlg = true;
  confirmPasswordFlg = true;
  public catpha_image_url: any;
  public catpha_image_word: any;
  public maxDate: any;
  selectedGender: any;
  selectedTitle: any;
  selectedStatus: any;
  selectedLanguage: Array<any> = [];
  selectedSpecialization: Array<any> = [];
  selectedOffer: Array<any> = [];
  selectedApproach: Array<any> = [];
  selectedAgegroup: Array<any> = [];
  selectedFamilyStatus: any;
  selectedGeneralAvailabilityWeekdayStatus: any;
  selectedGeneralAvailabilityWeekendStatus: any;
  selectedCounsellingStatus: any;
  countryCodeLst: any = [];

  get first_name() {
    return this.registerdocForm.get('first_name');
  }
  get last_name() {
    return this.registerdocForm.get('last_name');
  }
  get email() {
    return this.registerdocForm.get('email');
  }
  get gmail() {
    return this.registerdocForm.get('gmail');
  }
  get pan_number() {
    return this.registerdocForm.get('pan_number');
  }
  get phone() {
    return this.registerdocForm.get('phone');
  }
  get password() {
    return this.registerdocForm.get('password');
  }
  get confirmPassword() {
    return this.registerdocForm.get('confirmPassword');
  }
  get date_of_birth() {
    return this.registerdocForm.get('date_of_birth');
  }
  get doc_file() {
    return this.registerdocForm.get('doc_file');
  }
  get country_id() {
    return this.registerdocForm.get('country_id');
  }
  get state_id() {
    return this.registerdocForm.get('state_id');
  }
  get city() {
    return this.registerdocForm.get('city');
  }
  get zip_code() {
    return this.registerdocForm.get('zip_code');
  }

  get address() {
    return this.registerdocForm.get('address');
  }

  get profession_statement() {
    return this.registerdocForm.get('profession_statement');
  }
  get practicing_from() {
    return this.registerdocForm.get('practicing_from');
  }
  get consultation_fee() {
    return this.registerdocForm.get('consultation_fee');
  }
  get time_slot_per_patient_in_min() {
    return this.registerdocForm.get('time_slot_per_patient_in_min');
  }

  get phone_iso() {
    return this.registerdocForm.get('phone_iso');
  }
  get uploader_field() {
    return this.registerdocForm.get('uploader_field');
  }
  get gender() {
    return this.registerdocForm.get('gender');
  }
  get title() {
    return this.registerdocForm.get('title');
  }
  get recaptchaReactive() {
    return this.registerdocForm.get('recaptchaReactive');
  }
  get will_receive_news() {
    return this.registerdocForm.get('will_receive_news');
  }
  get termAndCondition() {
    return this.registerdocForm.get('termAndCondition');
  }
  get highest_qualification() {
    return this.registerdocForm.get('highest_qualification');
  }
  get graduation_in() {
    return this.registerdocForm.get('graduation_in');
  }
  get post_graduation() {
    return this.registerdocForm.get('post_graduation');
  }
  get research() {
    return this.registerdocForm.get('research');
  }
  get university_institute() {
    return this.registerdocForm.get('university_institute');
  }
  get physician() {
    return this.registerdocForm.get('physician');
  }
  get certificate() {
    return this.registerdocForm.get('certificate');
  }
  get qualifications() {
    return this.registerdocForm.get('qualifications');
  }
  get language() {
    return this.registerdocForm.get('language');
  }
  get practice_details() {
    return this.registerdocForm.get('practice_details');
  }
  get general_availability_weekday() {
    return this.registerdocForm.get('general_availability_weekday');
  }
  get general_availability_weekend() {
    return this.registerdocForm.get('general_availability_weekend');
  }
  get specializations() {
    return this.registerdocForm.get('specializations');
  }

  get therapies_offer() {
    return this.registerdocForm.get('therapies_offer');
  }
  get affiliations() {
    return this.registerdocForm.get('affiliations');
  }
  get family_counselling() {
    return this.registerdocForm.get('family_counselling');
  }
  get approach_to_care() {
    return this.registerdocForm.get('approach_to_care');
  }
  get name_in_bank() {
    return this.registerdocForm.get('name_in_bank');
  }
  get account_number() {
    return this.registerdocForm.get('account_number');
  }
  get bank_name() {
    return this.registerdocForm.get('bank_name');
  }
  get ifsc_code() {
    return this.registerdocForm.get('ifsc_code');
  }
  get trouble_uploading_docs() {
    return this.registerdocForm.get('trouble_uploading_docs');
  }
  get age_group() {
    return this.registerdocForm.get('age_group');
  }

  validPattern = '^[a-zA-Z0-9_-]{8,100}$';
  newSpecializationControl: FormControl;
  newOfferControl: FormControl;
  newLanguageControl: FormControl;
  showInputFieldLang = false; // Variable to control input field visibility
  showInputFieldSpecialization = false; // Variable to control input field visibility
  showInputFieldOffer = false; // Variable to control input field visibility
  newSpecialization = ''; // Variable to store the new specialization name
  newLanguage = ''; // Variable to store the new language name
  toggleInputFieldLang() {
    this.showInputFieldLang = !this.showInputFieldLang; // Toggle input field visibility
  }
  toggleInputFieldSpecialization() {
    this.showInputFieldSpecialization = !this.showInputFieldSpecialization; // Toggle input field visibility
  }
  toggleInputFieldOffer() {
    this.showInputFieldOffer = !this.showInputFieldOffer; // Toggle input field visibility
  }

  constructor(
    private service: UserService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public datePipe: DatePipe,

    private googleAuthService: SocialAuthService
  ) {
    this.newSpecializationControl = new FormControl('');
    this.newOfferControl = new FormControl('');
    this.newLanguageControl = new FormControl('');
    this.registrationSuccessed = false;
    this.invalidForm = false;
    this.errorMessage = '';
    this.registerdocForm = this.formBuilder.group({});
    this.newSpecializationForm = this.formBuilder.group({});
    this.newOfferForm = this.formBuilder.group({});
    this.newLanguageForm = this.formBuilder.group({});
    this.fileToUpload = '';
    this.fileToUploadTherapist = [];
    this.token = undefined;
    this.siteKey = environment.GOOGLE_SITE_KEY;
    this.type = 'image';
    this.theme = 'light';
    this.lang = 'en';
    this.size = 'normal';

    let reggie = /(\d{4})-(\d{2})-(\d{2})/;
    let date = new Date();
    let db_date = this.datePipe.transform(date, 'yyyy-MM-dd') as string;
    let datePartArray: Array<string> = reggie.exec(db_date) as Array<string>;
    let year = parseInt(datePartArray[1]);
    let month = parseInt(datePartArray[2]);
    let day = parseInt(datePartArray[3]);
    this.maxDate = { year: year, month: month, day: day };

    // this.catpha_image_url = 'assets/1616013019.2078.jpg';
    // this.catpha_image_word = 'ubWJY';
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return this.password?.value === this.confirmPassword?.value
      ? null
      : { mismatch: true };
  }
  onChangeTitle(event: any) {
    this.selectedTitle = event.target.value;
    console.log(this.selectedTitle, 666);
  }
  onChangeGender(event: any) {
    this.selectedGender = event.target.value;
  }
  onChangeStatus(event: any) {
    this.selectedStatus = event.target.value;
  }
  onChangeCounsellingStatus(event: any) {
    this.selectedCounsellingStatus = event.target.value;
  }
  onChangeGeneralAvailabilityWeekdayStatus(event: any) {
    this.selectedGeneralAvailabilityWeekdayStatus = event.target.value;
  }
  onChangeGeneralAvailabilityWeekendStatus(event: any) {
    this.selectedGeneralAvailabilityWeekendStatus = event.target.value;
  }
  onChangeFamilyStatus(event: any) {
    this.selectedFamilyStatus = event.target.value;
  }

  addCustomLanguage() {
    this.showCustomLanguageInput = true;
  }

  appendCustomLanguage($event) {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.customLanguage.trim() !== '') {
      this.languageLst.push({
        value: this.customLanguage,
        name: this.customLanguage,
      });
      this.selectedLanguage.push(this.customLanguage);
      this.customLanguage = '';
      this.showCustomLanguageInput = false;
    }
  }

  addLanguage($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.newLanguageControl.value.trim() !== '') {
      const newLanguageValue = this.newLanguageControl.value.trim();
      const newLanguageObject = {
        value: newLanguageValue,
        name: newLanguageValue,
      };
      this.languageLst.push(newLanguageObject);
      this.newLanguageControl.reset(); // Reset the input field

      // Add the new language value to the selectedLanguage array
      this.selectedLanguage = [...this.selectedLanguage, newLanguageValue];
      this.registerdocForm.value.language = this.selectedLanguage;
    }
    this.showInputFieldLang = false;
  }

  onChangeLanguage(event: any) {
    if (event.target.checked) {
      // Add the value of the checked checkbox to selectedLanguage
      this.selectedLanguage = [...this.selectedLanguage, event.target.value];
    } else {
      // Remove the value of the unchecked checkbox from selectedLanguage
      this.selectedLanguage = this.selectedLanguage.filter(
        (item) => item != event.target.value
      );
    }

    this.registerdocForm.value.language = this.selectedLanguage;
  }

  onChangeSpecialization(event: any) {
    if (event.target.checked) {
      this.selectedSpecialization = [
        ...this.selectedSpecialization,
        event.target.value,
      ];
    } else {
      this.selectedSpecialization = this.selectedSpecialization.filter(
        (item) => item != event.target.value
      );
    }
    this.registerdocForm.value.specializations = this.selectedSpecialization;
  }

  onChangeOffer(event: any) {
    if (event.target.checked) {
      this.selectedOffer = [...this.selectedOffer, event.target.value];
    } else {
      this.selectedOffer = this.selectedOffer.filter(
        (item) => item != event.target.value
      );
    }
    this.registerdocForm.value.therapies_offer = this.selectedOffer;
  }

  onChangeApproach(event: any) {
    if (event.target.checked) {
      this.selectedApproach = [...this.selectedApproach, event.target.value];
    } else {
      this.selectedApproach = this.selectedApproach.filter(
        (item) => item != event.target.value
      );
    }
    this.registerdocForm.value.approach_to_care = this.selectedApproach;
  }
  onChangeAgegroup(event: any) {
    if (event.target.checked) {
      this.selectedAgegroup = [...this.selectedAgegroup, event.target.value];
    } else {
      this.selectedAgegroup = this.selectedAgegroup.filter(
        (item) => item != event.target.value
      );
    }

    console.log(this.selectedAgegroup, 'this is age group');
    this.registerdocForm.value.age_group = this.selectedAgegroup;
  }

  noWhitespaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }
  ngOnInit(): void {
    this.getCountrCodes();
    var totalSteps = $('.steps li').length;
    console.log(this.activeStepIndex, 'Active Step Count');
    this.registerdocForm = this.formBuilder.group(
      {
        title: new UntypedFormControl(this.selectedTitle, [
          Validators.required,
        ]),
        first_name: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        last_name: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        phone_iso: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl(
          '',
          [
            Validators.required,
            Validators.pattern(/^\s*[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}\s*$/),
          ],
          AsyncValidators.emailExist(this.service)
        ),

        phone: new UntypedFormControl(
          '',
          [Validators.required, Validators.pattern('^[0-9]{10}$')],
          AsyncValidators.phoneExist(this.service)
        ),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
          ),
        ]),
        confirmPassword: new UntypedFormControl('', [Validators.required]),
        date_of_birth: new UntypedFormControl('', [Validators.required]),
        gender: new UntypedFormControl(this.selectedGender, [
          Validators.required,
        ]),
        address: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/[\S]/g),
        ]),
        country_id: new UntypedFormControl('', [Validators.required]),
        state_id: new UntypedFormControl('', [Validators.required]),
        city: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        zip_code: new UntypedFormControl('', [Validators.required]),
        // gmail: new UntypedFormControl('', [
        //   Validators.required,
        //   Validators.pattern('^[a-z0-9](.?[a-z0-9]){3,}@gmail.com$'),
        // ]),
        doc_file: new UntypedFormControl('', []),
        profession_statement: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        practicing_from: new UntypedFormControl('', [Validators.required]),
        consultation_fee: new UntypedFormControl('', [Validators.required]),
        time_slot_per_patient_in_min: new UntypedFormControl('', [
          Validators.required,
        ]),
        document_url: new UntypedFormControl(''),
        uploader_field: new UntypedFormControl('', [Validators.required]),
        highest_qualification: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        graduation_in: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        post_graduation: new UntypedFormControl('', []),
        research: new UntypedFormControl('', []),
        university_institute: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        physician: new UntypedFormControl('', [Validators.required]),
        certificate: new UntypedFormControl('', []),
        qualifications: new UntypedFormControl('', []),
        language: new UntypedFormControl('', [Validators.required]),
        practice_details: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        general_availability_weekend: new UntypedFormControl('', []),
        general_availability_weekday: new UntypedFormControl('', []),
        specializations: new UntypedFormControl('', [Validators.required]),
        therapies_offer: new UntypedFormControl('', [Validators.required]),
        affiliations: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
        ]),
        family_counselling: new UntypedFormControl('', [Validators.required]),
        approach_to_care: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3),
        ]),
        age_group: new UntypedFormControl('', [Validators.required]),
        name_in_bank: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/[\S]/g),
        ]),
        account_number: new UntypedFormControl('', [Validators.required]),
        bank_name: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(/[\S]/g),
        ]),
        ifsc_code: new UntypedFormControl('', [Validators.required]),
        pan_number: new UntypedFormControl('', [Validators.required]),
        trouble_uploading_docs: new UntypedFormControl('', []),

        will_receive_news: new UntypedFormControl(true),
        termAndCondition: new UntypedFormControl(
          false,
          Validators.requiredTrue
        ),
        user_type_id: new UntypedFormControl(2),
        pre_user_type_id: new UntypedFormControl(2),
        pre_user_id: new UntypedFormControl(0),
        // recaptchaReactive: ['', Validators.required],
      },
      {
        validator: AsyncValidators.confirmedValidator(
          'password',
          'confirmPassword'
        ),
      }
    );

    // this.title?.setValue('Mr.');
    this.email?.setValue(localStorage.getItem('Gmail'));
    this.service.countryList().subscribe((data) => {
      this.countryLst = data;
      this.country_id?.setValue(101);
    });

    this.service.stateList(101).subscribe((data) => {
      this.stateLst = data;
      this.state_id?.setValue(41);
    });
    this.googleAuthService.authState.subscribe((user) => {
      console.log(user.email);

      // this.user = user;
      // this.loggedIn = (user != null);
    });
    // this.getCaptcha();
  }

  getCountrCodes() {
    this.service.countryCodeList().subscribe((data) => {
      this.countryCodeLst = data;
      this.phone_iso?.setValue(+91);
    });
  }

  addSpecialization($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.newSpecializationControl.value.trim() !== '') {
      const newSpecializationValue = this.newSpecializationControl.value.trim();
      const newSpecializationObject = {
        value: newSpecializationValue,
        name: newSpecializationValue,
      };
      this.specializationLst.push(newSpecializationObject);
      this.newSpecializationControl.reset(); // Reset the input field
      // Add the new language value to the selectedLanguage array
      this.selectedSpecialization = [
        ...this.selectedSpecialization,
        newSpecializationValue,
      ];
      this.registerdocForm.value.specializations = this.selectedSpecialization;
    }
    this.showInputFieldSpecialization = false;
  }

  addOffer($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.newOfferControl.value.trim() !== '') {
      const newOfferValue = this.newOfferControl.value.trim();
      const newOfferObject = {
        value: newOfferValue,
        name: newOfferValue,
      };
      this.offerLst.push(newOfferObject);
      this.newOfferControl.reset(); // Reset the input field
      // Add the new language value to the selectedLanguage array
      this.selectedOffer = [...this.selectedOffer, newOfferValue];
      this.registerdocForm.value.therapies_offer = this.selectedOffer;
    }
    this.showInputFieldOffer = false;
  }

  onNextClick(event: Event) {
    $(window).scrollTop(500);
    if (this.activeStepIndex > 0 && this.activeStepIndex < 5) {
      let isFormValid = false;
      switch (this.activeStepIndex) {
        case 1:
          if (
            this.title?.valid &&
            this.first_name?.valid &&
            this.first_name?.value.trim() !== '' &&
            this.last_name?.valid &&
            this.last_name?.value.trim() !== '' &&
            this.email?.valid &&
            this.phone?.valid &&
            this.phone_iso?.valid &&
            this.password?.valid &&
            this.confirmPassword?.valid &&
            this.date_of_birth?.valid &&
            this.gender?.valid &&
            this.address?.valid &&
            this.address?.value.trim() !== '' &&
            this.country_id?.valid &&
            this.state_id?.valid &&
            this.city?.valid &&
            this.zip_code?.valid &&
            this.doc_file?.valid &&
            this.profession_statement?.valid
          ) {
            isFormValid = true;
          }

          break;

        case 2:
          console.log(this.language, '----Languages-----');

          if (
            this.highest_qualification?.valid &&
            this.graduation_in?.valid &&
            this.university_institute?.valid &&
            this.physician?.valid &&
            this.language?.valid
          ) {
            isFormValid = true;
          }

          break;
        case 3:
          if (
            this.practicing_from?.valid &&
            this.consultation_fee?.valid &&
            this.time_slot_per_patient_in_min?.valid &&
            this.practice_details?.valid &&
            this.general_availability_weekday?.valid &&
            this.general_availability_weekend?.valid &&
            this.specializations?.valid &&
            this.therapies_offer?.valid &&
            this.affiliations?.valid &&
            this.family_counselling?.valid &&
            this.approach_to_care?.valid &&
            this.age_group?.valid
          ) {
            isFormValid = true;
          }

          break;
        case 4:
          if (this.uploader_field?.valid && this.fileToUploadTherapist) {
            // this.fileToUploadTherapist.forEach((file) => {
            //   const maxSizeInBytes = 2 * 1024 * 1024;
            //   if (file.size > maxSizeInBytes) {
            //     this.certificationValid = this.certificationValid && false;
            //   } else {
            //     this.certificationValid = this.certificationValid && true;
            //   }
            // });
            isFormValid = this.certificationValid && true;
          }
          break;

        case 5:
          if (
            this.termAndCondition?.valid &&
            this.name_in_bank?.valid &&
            this.account_number?.valid &&
            this.pan_number?.valid &&
            this.bank_name?.valid &&
            this.ifsc_code?.valid
          ) {
            isFormValid = true;
          }
          break;
      }

      if (isFormValid) {
        this.setStepperActiveIndex.emit(this.activeStepIndex + 1);
        this.submitted = false;
      } else {
        this.submitted = true;
        // this.toastr.error('', 'Form is not valid');
      }
    }
  }

  onBackPress(event: Event) {
    $(window).scrollTop(500);
    if (this.activeStepIndex > 1 && this.activeStepIndex < 6) {
      this.setStepperActiveIndex.emit(this.activeStepIndex - 1);
    }
  }

  // handleFileInputTherepist(event: Event) {
  //   const input = event.target as HTMLInputElement;

  //   const fileinput = input.files?.item(0);
  //   console.log(fileinput, '------');

  //   if (!fileinput) {
  //     return;
  //   }

  //   const maxSizeInBytes = 2 * 1024 * 1024;
  //   if (fileinput.size > maxSizeInBytes) {
  //     this.toastr.error(
  //       '',
  //       'File size exceeds 2 MB. Please choose a smaller file.'
  //     );
  //     return;
  //   }

  //   const allowedFileTypes = [
  //     'image/png',
  //     'image/jpeg',
  //     'image/jpg',
  //     'application/pdf',
  //     'application/doc',
  //     'application/docx',
  //   ];

  //   if (!allowedFileTypes.includes(fileinput.type)) {
  //     this.toastr.error(
  //       '',
  //       'Invalid file type. Please choose a PNG, JPG, JPEG, PDF, DOC, DOCX file.'
  //     );
  //     return;
  //   }

  //   if (!input.files?.length) {
  //     return;
  //   }

  //   this.fileToUploadTherapist = input.files.item(0);
  //   this.uploader_field?.setValue(this.fileToUploadTherapist.name);
  // }

  handleFileInputTherepist(event: Event) {
    const input = event.target as HTMLInputElement;
    // const fileinput = input.files?.item(0);

    if (!this.fileToUploadTherapist) {
      return;
    }

    Array.from(this.fileToUploadTherapist).forEach((file) => {
      console.log(file.size, file.type, '=========');
      if (!this.allowedFileTypes.includes(file.type)) {
        this.toastr.error(
          '',
          'Invalid file type. Please choose a PNG, JPG, JPEG, PDF, DOC, DOCX file.'
        );

        this.certificationValid = this.certificationValid && false;
      } else {
        this.certificationValid = this.certificationValid && true;
      }

      // if (file.size > this.maxSizeInBytes) {
      //   this.toastr.error(
      //     '',
      //     'File size exceeds 2 MB. Please choose a smaller file.'
      //   );
      //   this.certificationValid = this.certificationValid && false;
      // } else {
      //   this.certificationValid = this.certificationValid && true;
      // }
    });
    if (this.certificationValid) {
      this.fileToUploadTherapist = Array.from(input.files as FileList);
    }

    if (!input.files?.length) {
      return;
    }
  }

  resetCertificate(event: Event) {
    this.uploader_field?.setValue(null);
    this.fileToUploadTherapist = [];
    $('input[formcontrolname=uploader_field]').val('');
  }

  changeCountry(e: any) {
    console.log(e);
    this.state_id?.setValue(e.target.value, {
      onlySelf: true,
    });

    this.service.stateList(this.state_id?.value).subscribe((data) => {
      this.stateLst = data;
    });
  }
  // handleFileInput(event: Event) {
  //   const input = event.target as HTMLInputElement;

  //   const fileInput = input.files?.item(0);
  //   console.log(fileInput, '------');

  //   if (!fileInput) {
  //     return;
  //   }

  //   const maxSizeInBytes = 2 * 1024 * 1024;
  //   if (fileInput.size > maxSizeInBytes) {
  //     this.toastr.error(
  //       '',
  //       'File size exceeds 2 MB. Please choose a smaller file.'
  //     );
  //     return;
  //   }

  //   const allowedFileTypes = [
  //     'image/png',
  //     'image/jpeg',
  //     'image/jpg',
  //     'image/gif',
  //   ];

  //   if (!allowedFileTypes.includes(fileInput.type)) {
  //     this.toastr.error(
  //       '',
  //       'Invalid file type. Please choose a PNG, JPG, JPEG, or GIF file.'
  //     );
  //     return;
  //   }

  //   if (!input.files?.length) {
  //     return;
  //   }
  //   if (fileInput.size < maxSizeInBytes) {
  //     this.fileToUpload = input.files.item(0);
  //   } else {
  //     this.doc_file?.setValue('');
  //     return;
  //   }

  //   if (this.fileToUpload.size <= maxSizeInBytes) {
  //     this.service.uploadFile(this.fileToUpload).subscribe(
  //       (data: boolean) => {
  //         console.log(data, 666);

  //         this.spinner.hide();
  //         // Optionally display a success message
  //         // this.toastr.success('', 'Your profile picture updated successfully.');
  //         // this.fileToUpload = '';
  //         this.doc_file?.setValue(data);
  //       },
  //       (error: any) => {
  //         this.spinner.hide();
  //         this.toastr.error('', error); // Display the error message
  //       }
  //     );
  //   }
  // }

  //

  handleFileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const fileInput = input.files?.item(0);

    if (!fileInput) {
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024;
    const allowedFileTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/gif',
    ];

    // if (fileInput?.size > maxSizeInBytes) {
    //   this.doc_file?.setErrors({ maxSizeExceeded: true });
    //   this.toastr.error(
    //     '',
    //     'File size exceeds 2 MB. Please choose a smaller file.'
    //   );
    //   return;
    // }

    if (!allowedFileTypes.includes(fileInput.type)) {
      this.doc_file?.setErrors({ invalidFileType: true });
      this.toastr.error(
        '',
        'Invalid file type. Please choose a PNG, JPG, JPEG or GIF file.'
      );
      return;
    }

    this.fileToUpload = fileInput;

    this.service.uploadFile(this.fileToUpload).subscribe(
      (data: boolean) => {
        console.log(data, 666);
        this.spinner.hide();
        // Optionally display a success message
        // this.toastr.success('', 'Your profile picture updated successfully.');
        // this.fileToUpload = '';
        this.doc_file?.setValue(data);
        this.doc_file?.setErrors(null); // Clear any previous validation errors
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error('', error); // Display the error message
      }
    );
  }

  reset(event: Event) {
    // Implement reset logic as needed
    this.doc_file?.setValue('');
    this.fileToUpload = null;
  }

  get f() {
    return this.registerdocForm.controls;
  }
  // reset(event: Event) {
  //   this.fileToUpload = 0;
  //   console.log(this.fileToUpload.name, 8888);

  //   $('input[formcontrolname=doc_file]').val('');
  // }
  // resetCertificate(event: Event) {
  //   this.fileToUploadTherapist = 0;
  //   $('input[formcontrolname=uploader_field]').val('');
  // }

  docregister() {
    this.submitted = true;
    if (this.registerdocForm.invalid) {
      this.toastr.error('', 'Form is not valid');
      return;
    }

    // if (this.recaptchaReactive?.value == null) {
    //   this.toastr.error('', 'Captcha is not valid.');
    //   // this.getCaptcha();
    //   return;
    // }

    this.spinner.show();
    const uploadDocumentsObservable =
      this.fileToUploadTherapist.length > 0
        ? this.service.uploadDocuments(this.fileToUploadTherapist) || [of(null)]
        : of(null);

    forkJoin(
      [this.service.uploadFile(this.fileToUpload)].concat(
        uploadDocumentsObservable
      )
    ).subscribe((formData) => {
      const uploadedPic = formData[0];
      const uploadedDocs = formData.slice(1);
      this.registerdocForm.value.language = this.selectedLanguage;
      this.registerdocForm.value.specializations = this.selectedSpecialization;
      this.registerdocForm.value.therapies_offer = this.selectedOffer;
      this.registerdocForm.value.approach_to_care = this.selectedApproach;
      this.registerdocForm.value.age_group = this.selectedAgegroup;

      let jasonObj: any = this.registerdocForm.value;
      console.log(jasonObj, 8888);
      let practicing_from = jasonObj.practicing_from;
      jasonObj.document_url = uploadedPic;
      let month: string;
      if (practicing_from.month < 10) {
        month = '0' + practicing_from.month;
      } else {
        month = practicing_from.month;
      }

      let days: string;
      if (practicing_from.day < 10) {
        days = '0' + practicing_from.day;
      } else {
        days = practicing_from.day;
      }
      jasonObj.practicing_from =
        practicing_from.year + '-' + month + '-' + days;

      let date_of_birth = jasonObj.date_of_birth;
      if (date_of_birth.month < 10) {
        month = '0' + date_of_birth.month;
      } else {
        month = date_of_birth.month;
      }

      if (date_of_birth.day < 10) {
        days = '0' + date_of_birth.day;
      } else {
        days = date_of_birth.day;
      }

      jasonObj.date_of_birth = date_of_birth.year + '-' + month + '-' + days;

      jasonObj.uploader_field = uploadedDocs;
      this.authService.register(jasonObj).subscribe(
        (data: boolean) => {
          this.spinner.hide();
          this.toastr.success(
            '',
            'You have successfully registered as Counsellor/Therapist, please verify your email before logging in'
          );
          // this.registerdocForm.reset();
          // this.title?.setValue('Mr.');
          // this.country_id?.setValue(101);
          // this.will_receive_news?.setValue(true);
          // this.orgPasswordFlg = true;
          // this.confirmPasswordFlg = true;
          // this.getCaptcha();
          this.router.navigate(['home']);
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error('', error || 'Something Went Wrong');
          // this.getCaptcha();
        }
      );
    });
  }
  next() {}

  showHidePassword() {
    this.orgPasswordFlg = !this.orgPasswordFlg;
  }
  showHideConfirmPassword() {
    this.confirmPasswordFlg = !this.confirmPasswordFlg;
  }
  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    console.debug(`Token [${this.token}] generated`);
  }
}
