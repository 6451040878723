import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  passwordFlg = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.loginForm = this.formBuilder.group({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      rememberMe: new UntypedFormControl(false),
      loginType: 1,
    });
  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit(): void {}
  signIn() {
    if (this.loginForm.invalid) {
      this.toastr.error('', 'Form is not valid');
      return;
    }
    let jasonObj: any = this.loginForm.value;
    this.spinner.show();
    this.authService.adminLogin(jasonObj).subscribe(
      (logindata: any) => {
        this.spinner.hide();
        if (logindata == null) {
          this.toastr.error('', 'You are not the administrator');
        } else {
          window.location.href = 'admin/home';
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error('', error);
      }
    );
  }
  showHidePassword() {
    this.passwordFlg = !this.passwordFlg;
  }
}
