<div class="wrapper dashbord" id="page" style="background: #fff">
  <app-patient-header></app-patient-header>

  <!-- 
  <div class="banner">
    <img src="assets/images/bnr.jpg" alt="banner-bg/" class="w-100" />
  </div> -->
  <main class="site-main layout_pg">
    <div class="desh_main">
      <div class="left_desk">
        <app-patient-navbar></app-patient-navbar>
      </div>
      <div class="right_pnal">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <app-connect-form></app-connect-form>
  <app-footer></app-footer>
</div>
