<span class="oepnclose_btn" (click)="toggleCollapse()">
  <i
    class="zmdi"
    [ngClass]="{
      'zmdi-chevron-left': !isCollapsed,
      'zmdi-chevron-right': isCollapsed
    }"
  ></i>
</span>
<div class="left_pnal_iner">
  <div class="profile">
    <figure>
      <img
        src="{{
          currentUser.profile_img ? currentUser.profile_img : imagePath
        }}"
        alt="therapist-img"
      />
    </figure>
    <h4>
      {{ currentUser.title }} {{ currentUser.first_name }}
      {{ currentUser.last_name }}
    </h4>
    <span>{{ currentUser.profession_statement }}</span
    ><br />
    <!-- <p>M Phill. MA.</p> -->
    <!-- {{ currentUser.title + " " + currentUser.first_name +  " " +  currentUser.last_name }} -->
    <!-- <span> {{ currentUser.profession_statement }}</span>
      <p>M Phil. MA.</p> -->
    <span class="coc_code">COC ID : {{ currentUser.unique_id }}</span>
  </div>
  <div class="navlist">
    <ul>
      <li [routerLinkActive]="['active']">
        <a routerLink="home">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon.png" alt="" />
            <img class="hover_icon" src="assets/images/nav-icon-h.png" alt="" />
          </span>
          Dashboard</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="my-appointments">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon2.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon2-h.png"
              alt=""
            />
          </span>
          Appointments</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="my-patients">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon3.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon3-h.png"
              alt=""
            />
          </span>
          YOUR clients</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="{{ link }}">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon4.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon4-h.png"
              alt=""
            />
          </span>
          Schedule Timings</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="assessment-list">
          <span>
            <img
              class="normal_icon"
              src="assets/images/assessment_icon.png"
              alt=""
            />
            <img
              class="hover_icon"
              src="assets/images/assessment_icon2.png"
              alt=""
            />
          </span>
          Assessment List</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="refer-therapist">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon5.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon5-h.png"
              alt=""
            />
          </span>
          Recommend CoC</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="profile">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon6.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon6-h.png"
              alt=""
            />
          </span>
          Profile Settings</a
        >
      </li>
      <li [routerLinkActive]="['active']">
        <a routerLink="change-password">
          <span>
            <img class="normal_icon" src="assets/images/nav-icon7.png" alt="" />
            <img
              class="hover_icon"
              src="assets/images/nav-icon7-h.png"
              alt=""
            />
          </span>
          Change Password</a
        >
      </li>
    </ul>
  </div>

  <div class="log_btn">
    <a routerLink="/logout" class="yellow_btn">LOG OUT</a>
  </div>
</div>
