import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailEncoding',
})
export class EmailEncodingPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return '';
    }

    if (value?.length < 4) {
      return value;
    }

    var lastFour = value?.substring(4, value?.length);
    var firstPart = '';
    for (let i = 0; i < 4; i++) {
      firstPart += '*';
    }
    return firstPart + lastFour;
  }
}
