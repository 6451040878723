<div class="main-wrapper" style="transform: none;">
    <div class="content" style="transform: none; min-height: 200px;">
      <div class="container-fluid" style="transform: none;">
        <div class="row" style="transform: none;">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>