<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Contact Us: Changing Minds and Healing Hearts Together</title>
    <meta
      name="description"
      content="Reach out to us for support in changing minds and healing hearts. We're here to guide you on your journey to better mental health and well-being"
    />
    <meta name="keywords" content="changing mind, healing heart" />

    <!-- Your other meta tags, stylesheets, and scripts go here -->
  </head>
  <body>
    <section
      class="contact_form"
      style="
        background: url('assets/images/contact_img.jpg') no-repeat center center /
          cover;
      "
    >
      <div class="container">
        <div class="inner_form">
          <div class="sec_title">
            <span>R</span>
            <p>Reach Out for Help</p>
            <h2><mark>connect</mark> now</h2>
          </div>

          <div class="contact_outer">
            <form
              [formGroup]="addForm"
              (ngSubmit)="saveInfo()"
              autocomplete="off"
            >
              <div class="row gy-3">
                <div class="col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Name"
                    formControlName="user_name"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.user_name.errors"
                  >
                    <div *ngIf="f.user_name?.errors?.required">
                      User Name is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Email Address"
                    formControlName="email"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.email.errors"
                  >
                    <div *ngIf="f.email?.errors?.required">
                      Email is required
                    </div>
                    <div *ngIf="f.email.errors.pattern">
                      Invalid input for Email
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Title Message"
                    formControlName="title"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.title.errors"
                  >
                    <div *ngIf="f.title?.errors?.required">
                      Title Message is required
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <textarea
                    class="form-control"
                    placeholder="Message"
                    formControlName="msg"
                  ></textarea>
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.msg.errors"
                  >
                    <div *ngIf="f.msg?.errors?.required">
                      Message is required
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 text-center form_btn">
                  <input type="submit" value="SEND MESSAGE NOW" />
                </div>
              </div>
            </form>
            <div class="form_info">
              <p>
                Write to
                <a href="mailto:support@careocure.com"
                  >support&#64;careocure.com</a
                >
                for technical glitches and to
                <a href="mailto:info@careocure.com">info&#64;careocure.com</a>
                for other queries
              </p>
              <p>
                Call us:
                <a href="tel:+919830568888">+91 98305 68888</a>
                | Reach-Out Times:
                <span> 10 AM to 7 PM </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
