// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css'],
})
export class PublicHeaderComponent implements OnInit {
  menuv: boolean = true;
  isLoggedIn: boolean = false;
  loggedUserName: string = '';
  isAdmin: boolean = false;
  closeResult: string;
  userTypeId: number = 0;

  loadLogin: boolean = false;
  loadDoctor: boolean = false;
  loadPatient: boolean = false;
  constructor(
    private authService: AuthService,

    private modalService: NgbModal,
    private router: Router
  ) {
    this.closeResult = '';
    this.isLoggedIn = this.authService.isLoggedIn();

    if (this.isLoggedIn) {
      let currentUser = this.authService.currentUser;
      this.userTypeId = currentUser.user_type_id;
      this.loggedUserName =
        currentUser.first_name + ' ' + currentUser.last_name;
    }
  }

  ngOnInit(): void {
    this.authService.getLoggedInfo.subscribe((logindata: any) => {
      this.isLoggedIn = this.authService.isLoggedIn();
      console.log('Loggin Flag::' + this.isLoggedIn);

      if (this.isLoggedIn) {
        this.loggedUserName = logindata.first_name + ' ' + logindata.last_name;
        this.isAdmin = false;
        let currentUser = this.authService.currentUser;
        this.userTypeId = currentUser.user_type_id;
        if (logindata.user_type_id == 1) {
          this.isAdmin = true;
        }
      } else {
        this.loggedUserName = '';
        this.isAdmin = false;
      }
    });
  }
  goToLogin() {
    this.router.navigate(['/login']);

    // Scroll to position below 1200px after a slight delay to ensure navigation completes
    setTimeout(() => {
      window.scrollTo({ top: 500, behavior: 'smooth' });
    }, 300);
  }
  closeCanvas() {
    document
      .querySelector('.offcanvas-body ul li a')
      ?.addEventListener('click', () => {
        document.querySelector('.offcanvas')?.classList.remove('show');
        document.querySelector('.offcanvas-backdrop')?.classList.remove('show');
      });
  }

  goToTherpists() {
    // Scroll to position below 1200px after a slight delay to ensure navigation completes
    setTimeout(() => {
      window.scrollTo({ top: 700, behavior: 'smooth' });
    }, 300);
  }
}
