import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userDisplay'
})
export class UserDisplayPipe implements PipeTransform {

  transform(value: any): unknown {
    console.log(value);
    return value.name;
  }

}
