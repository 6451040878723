<header class="site-header">
  <div class="container">
    <div class="header-hldr d-flex align-items-center">
      <h1>
        <a routerLink="/home" class="logo" title="Care-O-Cure"
          ><img src="assets/images/logo.png" alt="Logo"
        /></a>
      </h1>
      <div class="main-menu">
        <nav>
          <ul>
            <li>
              <a routerLink="/home" [routerLinkActive]="['active']">Home</a>
            </li>
            <li>
              <a routerLink="/about-us" [routerLinkActive]="['active']"
                >About</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/therapy-seekers']"
                [queryParams]="{ tab: 'individuals' }"
                routerLinkActive="active"
                >Therapy Seekers</a
              >
            </li>
            <li>
              <a
                (click)="goToTherpists()"
                style="cursor: pointer"
                routerLink="/therapists"
                routerLinkActive="active"
                >Therapists</a
              >
            </li>

            <!-- <li><a routerLink="practitioners">Practitioners </a></li> -->
          </ul>
        </nav>

        <a
          class="mobilemenuBtn"
          href="#url"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>

      <div class="userlogin">
        <ul>
          <li *ngIf="!isLoggedIn">
            <a (click)="goToLogin()" style="cursor: pointer">
              <img src="assets/images/login.png" alt="login" />
              login
            </a>
          </li>
          <!-- <li ngbDropdown class="nav-item dropdown" *ngIf="isLoggedIn">
            <a
              ngbDropdownToggle
              class="nav-link dropdown-toggle btn btn-sm border border-dark"
              id="dropdown1"
            >
              {{ loggedUserName }}
            </a>
            <div
              ngbDropdownMenu
              class="dropdown-menu"
              aria-labelledby="dropdown1"
            >
              <a
                class="dropdown-item"
                routerLink="/admin/home"
                *ngIf="userTypeId == 1"
                >My Dashboard</a
              >
              <a
                class="dropdown-item"
                routerLink="/patient/home"
                *ngIf="userTypeId == 3"
                >My Dashboard</a
              >
              <a
                class="dropdown-item"
                routerLink="/doctor/home"
                *ngIf="userTypeId == 2"
                >My Dashboard</a
              >
              <a class="dropdown-item" routerLink="/logout">Logout</a>
            </div>
          </li> -->
          <div class="public_header" *ngIf="isLoggedIn">
            <p>WELCOME</p>
            <h5>{{ loggedUserName }}</h5>
            <span *ngIf="userTypeId == 3">THERAPY SEEKER</span>
            <span *ngIf="userTypeId == 2">THERAPIST</span>
            <span *ngIf="userTypeId == 1">ADMINISTRATOR</span>
            <span *ngIf="userTypeId == 7">SUB ADMINISTRATOR</span>
            <ul>
              <li>
                <a routerLink="/admin/home" *ngIf="userTypeId == 1"
                  >YOUR DASHBOARD</a
                >
                <a routerLink="/admin/home" *ngIf="userTypeId == 7"
                  >YOUR DASHBOARD</a
                >
                <a routerLink="/therapy-seeker/home" *ngIf="userTypeId == 3"
                  >YOUR DASHBOARD</a
                >
                <a routerLink="/therapist/home" *ngIf="userTypeId == 2"
                  >YOUR DASHBOARD</a
                >
              </li>
              <li><a routerLink="/logout">LOG OUT</a></li>
            </ul>
          </div>
          <li *ngIf="!isLoggedIn">
            <!-- (click)="openPatientModal()" -->
            <a routerLink="register">
              <img src="assets/images/register.png" alt="register" />
              register
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="offcanvas-header">
    <!-- <h5 class="offcanvas-title" id="offcanvasExampleLabel" routerLink="/">
      CareOCure
    </h5> -->
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <nav id="menu">
      <ul (click)="closeCanvas()">
        <li><a routerLink="home">Home</a></li>
        <li><a routerLink="about-us">About</a></li>
        <li>
          <a
            [routerLink]="['/therapy-seekers']"
            [queryParams]="{ tab: 'individuals' }"
            routerLinkActive="active"
            >Therapy Seekers</a
          >
        </li>
        <li>
          <a
            (click)="goToTherpists()"
            style="cursor: pointer"
            routerLink="/therapists"
            >Therapists</a
          >
        </li>
      </ul>
    </nav>
  </div>
</div>
