import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-patient-navbar',
  templateUrl: './patient-navbar.component.html',
  styleUrls: ['./patient-navbar.component.css'],
})
export class PatientNavbarComponent implements OnInit {
  currentUser: any;
  id: any;
  isCollapsed: boolean = false;
  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.currentUser = this.authService.currentUser;
    console.log(this.currentUser, 1111);
    this.id = this.currentUser.user_id;
  }

  ngOnInit(): void {
    this.authService.getLoggedInfo.subscribe((logindata: any) => {
      this.currentUser = logindata;
      console.log(this.currentUser, 'Logindata');
    });
  }
  goToTherpists() {
    this.router.navigate(['/therapists']);

    // Scroll to position below 1200px after a slight delay to ensure navigation completes
    setTimeout(() => {
      window.scrollTo({ top: 700, behavior: 'smooth' });
    }, 300);
  }
  closeMenu() {
    document.querySelector('.close_icon')?.addEventListener('click', () => {
      document.querySelector('.left_desk')?.classList.remove('active_menu');
      document.body.classList.remove('overlay');
      // document.getElementsByTagName('html')[0].style.overflow = "hidden";
    });
  }

  AnchorClickToMenuClose() {
    document.querySelector('.left_desk ul')?.addEventListener('click', () => {
      document.querySelector('.left_desk')?.classList.remove('active_menu');
      document.body.classList.remove('overlay');
      // document.getElementsByTagName('html')[0].style.overflow = "hidden";
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    document.querySelector('.desh_main')?.classList.toggle('active');
    document.getElementsByTagName('body')[0].classList.toggle('overlay');
  }
}
