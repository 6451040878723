<footer class="site-footer">
  <div class="container">
    <div class="iner_fter">
      <div class="row gy-4 align-items-center">
        <div class="col-lg-4 col-md-5 fter_logo" id="first-sec">
          <figure>
            <a routerLink="/">
              <img src="assets/images/footer_logo.svg" alt="" />
            </a>
          </figure>
          <ul>
            <li><a routerLink="refund-policy">refund policy</a></li>
            <li><a routerLink="privacy-policy">Privacy Policy</a></li>
            <li>
              <a routerLink="terms-and-conditions">Terms And Condition</a>
            </li>
          </ul>
          <p>&copy; {{ year }} - All Rights Reserved by Care O Cure</p>
        </div>
        <div class="col-lg-5 col-md-4 fter_links">
          <ul>
            <li><a routerLink="/home">HOME</a></li>
            <li><a routerLink="/about-us">ABOUT</a></li>
            <li>
              <a
                [routerLink]="['/therapy-seekers']"
                [queryParams]="{ tab: 'individuals' }"
                routerLinkActive="active"
                >THERAPY SEEKERS</a
              >
            </li>
            <li><a routerLink="/therapists">THERAPISTS</a></li>
            <li><a routerLink="/blogs">BLOGS</a></li>
            <li><a routerLink="/services">SERVICES</a></li>
          </ul>
        </div>
        <div class="col-md-3 fter_socail">
          <div class="right_socail_box">
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/careocuredotcom?mibextid=LQQJ4d"
                  ><i class="zmdi zmdi-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/careocuredotcom?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D"
                  ><i class="zmdi zmdi-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/careocure/about/"
                  ><i class="zmdi zmdi-linkedin"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://wa.me/9830568888?text=Hi! Need help. Can you assist?"
                >
                  <i class="zmdi zmdi-whatsapp"></i>
                </a>
              </li>

              <!-- <li>
                <a href="#url"><i class="zmdi zmdi-youtube-play"></i></a>
              </li>  -->
            </ul>
            <a routerLink="/gift-session" class="black_btn">
              <img src="assets/images/gift_icon.png" alt="" />GIFT A SESSION</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="to-top"
    (click)="scrollToTop()"
    [ngClass]="{ 'show-scrollTop': windowScrolled }"
  >
    <img src="assets/images/arrow_up.png" alt="" />
  </div>
</footer>
