<main class="site-main inner_pg_site">
  <div class="banner inner_pg_bnr pb-0">
    <img class="w-100" src="assets/images/about_bnr.jpg" alt="" />
    <div class="outer_bnr">
      <div class="container">
        <h2>about us</h2>
      </div>
    </div>
  </div>
  <section class="bradcmp">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">About Us</li>
        </ol>
      </nav>
    </div>
  </section>
</main>
