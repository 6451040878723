<div class="log_in_modal register_model reg-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-0">
        <button
          type="button"
          class="btn-close log_in_close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"
        >
          <!-- <span aria-hidden="true">&times;</span> -->
        </button>
        <div class="log_in_form">
          <h5 class="form-signin-heading">Register As Patient Or Doctor</h5>

          <form action="/">
            <p>Please select one:</p>
            <ul class="form-radio">
              <li *ngFor="let radiobutton of radioItems">
                <label>
                  <input
                    type="radio"
                    name="options"
                    required
                    (click)="model.option = radiobutton"
                    [checked]="radiobutton === 'as a doctor'"
                  />
                  <span> {{ radiobutton }}</span>
                </label>
              </li>
              <li></li>
            </ul>

            <div class="col-lg-12 text-center">
              <button type="submit" class="yellow_btn" (click)="openModal()">
                Register
              </button>
            </div>
          </form>
        </div>
        <div class="log_im_img">
          <img src="assets/images/reg-bg.jpg" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div>
