// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ********LIVE URL************
  baseUrl: 'https://www.careocure.com/',

  // ********DEV URL************
  //baseUrl: 'https://devteamworks.in/careocure/',
  // baseUrl: 'http://43.204.12.106/coc/',
  GAPI_CLIENT_ID:
    '171641208809-aprgaqnvkvg11oq1l24hn8kodb293pj8.apps.googleusercontent.com',
  clientId:
    '171641208809-aprgaqnvkvg11oq1l24hn8kodb293pj8.apps.googleusercontent.com',
  appId: '930527258557681',
  // zoom_client_id: 'AgPj8uqWQ62MeSRBUuMojg',
  zoom_client_id: '85glFcOIRGOod2TTRkRNA',
  zoom_redirect_uri: 'https://www.careocure.com/zoom/meeting',
  name: 'Dev',
  //for local
  // imagePath: 'https://devteamworks.in/careocure-backend/public/imageRender/',

  //for live
  imagePath: 'https://www.careocure.com/coc-backend/writable/uploads/',

  // api_baseUrl:"http://localhost/careocure-backend/public",
  // api_baseUrl: 'https://careocure.com/careocure/public',

  // ********DEV API URL************
  //api_baseUrl: 'https://devteamworks.in/careocure-backend/public',

  //PDF link dev
  pdf_url: 'https://www.careocure.com/coc-backend/public/api',
  //PDF link live
  //pdf_url:'https://www.careocure.com/coc-backend/public/api',

  image_url: 'https://careocure.com/coc-backend/public/imageRender/',

  // **********LIVE API URL************
  api_baseUrl: 'https://www.careocure.com/coc-backend/public',

  GOOGLE_SITE_KEY: '6LfKiKElAAAAAHsoKHGfb4cCNJ0WHOoe2CWxoesh',
  recaptcha: {
    siteKey: '6LfKiKElAAAAAHsoKHGfb4cCNJ0WHOoe2CWxoesh',
  },
};
// code:-4/0AbUR2VMciLYvOU4liHbsQHPeN_7fIC5stdiJMZhId6RykGzdeTi8ZlTZ9EAZBOI3WHp6-Q
// Calendar Id:-tdc4daq1oeqh8j2j82t67csitc2tro05@import.calendar.google.com
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
