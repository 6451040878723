import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseurl = '';
  constructor(private http: HttpClient) {
    this.baseurl = environment.api_baseUrl;
  }

  countryList() {
    return this.http.get(this.baseurl + '/api/country-list').pipe(
      retry(2),
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  spacialistList() {
    return this.http.get(this.baseurl + '/api/spacialist-list').pipe(
      retry(2),
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  stateList(state_id: number) {
    return this.http.get(this.baseurl + '/api/state-list/' + state_id).pipe(
      retry(2),
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }
  countryCodeList() {
    return this.http.get(this.baseurl + '/api/index-country-dial-code/').pipe(
      retry(2),
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  professionList() {
    return this.http.get(this.baseurl + '/api/profession-list').pipe(
      retry(2),
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  existEmail(email: string) {
    return this.http.get(this.baseurl + '/api/exist/' + email + '/email').pipe(
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        if (result.exist) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  sendReport(formdata: any, doctor_id: number) {
    // Construct the URL with the doctor_id included
    const url = `${this.baseurl}/api/doctor-share/${doctor_id}`;
    return this.http.post(url, JSON.stringify(formdata)).pipe(
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(formdata);
        return result;
      })
    );
  }

  existPhone(phone: string) {
    return this.http.get(this.baseurl + '/api/exist/' + phone + '/phone').pipe(
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        if (result.exist) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  uploadDocuments(fileToUploadTherapist?: File[]) {
    const endpoint = this.baseurl + '/api/uploadfile';

    const observables = fileToUploadTherapist?.map((file) => {
      const formData: FormData = new FormData();

      formData.append('doc_file', file, file.name);
      return this.http.post(endpoint, formData).pipe(
        catchError((error: any) => {
          return throwError(error.error.messages.error);
        }),
        map((result: any) => {
          return result.path;
        })
      );
    });
    return observables;
  }

  uploadFile(fileToUpload: File) {
    const endpoint = this.baseurl + '/api/uploadfile';
    const formData: FormData = new FormData();
    formData.append('doc_file', fileToUpload, fileToUpload.name);

    return this.http.post(endpoint, formData).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages.error);
      }),
      map((result: any) => {
        console.log(result);

        return result.path;
      })
    );
  }
  uploadVideo(videoToUpload: File, userId: Number) {
    const endpoint = this.baseurl + '/api/uploadvideo';
    const formData: FormData = new FormData();
    formData.append('vid_file', videoToUpload, videoToUpload.name);
    formData.append('user_id', userId.toString());

    return this.http.post(endpoint, formData).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages.error);
      }),
      map((result: any) => {
        console.log(result);
        return result.path;
      })
    );
  }

  getUserProfile(user_id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/profile/' + user_id, options)
      .pipe(
        retry(2),
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data;
        })
      );
  }

  getTherapistProfile(user_id: number, payload: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json', // Assuming your payload is JSON
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/profile/' + user_id, payload, options)
      .pipe(
        retry(2),
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data;
        })
      );
  }

  giverating(formdata: string, doctor_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + `/api/update-star-rating/${doctor_id}`,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getDoctors(searchJson: any) {
    return this.http
      .post(this.baseurl + '/api/find-doctor', JSON.stringify(searchJson))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.doctors;
        })
      );
  }

  getAuthenticationToken() {
    let myRawToken = localStorage.getItem('token') as string;
    if (myRawToken != null) {
      return myRawToken;
    }

    myRawToken = sessionStorage.getItem('token') as string;
    if (myRawToken != null) {
      return myRawToken;
    }

    return null;
  }

  referDoctor(formdata: any, patient_doctor_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/refer', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }

  addHistory(
    formdata: any,
    doctor_id: number,
    patient_id: number,
    booking_id: number
  ) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/add-medical-history', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }

  addMood(formdata: any) {
    return this.http
      .post(this.baseurl + '/api/mood-add', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return result;
        })
      );
  }
  addFeedback(formdata: any) {
    return this.http
      .post(
        this.baseurl + '/api/session-feedback-add',
        JSON.stringify(formdata)
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return result;
        })
      );
  }

  medicalHistoryList(dataTablesParameters: any, patient_id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });
    let options = {
      headers: headers,
    };
    let payload = {
      draw: dataTablesParameters.draw,
      start: dataTablesParameters.start,
      length: dataTablesParameters.length,
      search: dataTablesParameters.search.value,
    };

    return this.http
      .get(this.baseurl + '/api/medical-history-list/' + patient_id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  moodChecker(patient_id: number) {
    return this.http
      .get(this.baseurl + '/api/check-mood-details/' + patient_id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  medicalHistoryDetails(dataTablesParameters: any, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };
    return this.http
      .get(this.baseurl + '/api/medical-history-details/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  encryptdata(request) {
    console.log(request);
    // let url = `${this.baseurl}/orders/encryptFormData`;
    let url = this.baseurl + '/api/book-appointments';
    // let url = this.baseurl + '/api/gift-list';
    // let data = {
    //   request: request,
    // };
    // return this.http.post(url, { params: data });
    return this.http.post(url, JSON.stringify(request));
    // return this.http.get(url);
  }
}
