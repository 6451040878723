<section
  class="info_sec p-0"
  style="background: url(assets/images/login_bnr.jpg) no-repeat 0 0 / cover"
>
  <div class="container">
    <div class="forget_iner">
      <div
        class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 align-items-center"
      >
        <div class="col order-lg-1 order-2">
          <img src="assets/images/new_pass.svg" alt="" />
        </div>
        <div class="col order-lg-2 order-1">
          <form
            class="common_form info_form"
            [formGroup]="changePasswordForm"
            (ngSubmit)="changePasword()"
            autocomplete="off"
          >
            <h3 class="normal_head">Reset Your Password</h3>

            <div class="error_holder">
              <label>New Password</label>
              <div class="filed_box">
                <input
                  type="password"
                  class="form-control"
                  formControlName="new_password"
                  placeholder="Enter New Password"
                  [type]="passwordFlg ? 'password' : 'text'"
                  [disabled]="newPasswordDisabled"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    type="button"
                    (click)="showHidePassword()"
                  >
                    <i
                      [class]="passwordFlg ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div
                class="alert alert-danger error_msg"
                *ngIf="new_password?.touched && !new_password?.valid"
              >
                <div *ngIf="new_password?.errors?.required">
                  Password is required
                </div>
                <div
                  *ngIf="
                    new_password?.touched && new_password?.hasError('pattern')
                  "
                >
                  <p>Password must meet the following criteria:</p>
                  <ul>
                    <li>At least 8 characters in length</li>
                    <li>At least one uppercase letter</li>
                    <li>At least one lowercase letter</li>
                    <li>At least one number</li>
                    <li>At least one special character (e.g., #?!@$%^&*-)</li>
                  </ul>
                </div>
              </div>
              <div class="submit_form_btn">
                <input
                  type="submit"
                  value="Change Password"
                  [disabled]="newPasswordDisabled"
                  class="submit_btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner type="ball-spin">
  <p style="color: white">
    <img
      src="assets/images/loader.gif"
      width="60"
      height="60"
      alt="loading..."
    />
  </p>
</ngx-spinner>
