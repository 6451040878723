import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DoctorRegistrationFormComponent } from '../doctor-registration-form/doctor-registration-form.component';

@Component({
  selector: 'app-google-user-reg-form',
  templateUrl: './google-user-reg-form.component.html',
  styleUrls: ['./google-user-reg-form.component.css'],
})
export class GoogleUserRegFormComponent implements OnInit {
  radioItems: Array<string>;
  model = { option: '' };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal
  ) {
    this.radioItems = ['as a doctor', 'as a patient'];
  }
  ngOnInit(): void {}
  openModal() {
    console.log('clicked');

    if (this.model.option == 'as a patient') {
      this.activeModal.close('ddd sss');
    } else {
      this.activeModal.close('ddd sss');
      const modalRef = this.modalService.open(DoctorRegistrationFormComponent);
    }
  }
}
