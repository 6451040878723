import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../services/admin.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-connect-form',
  templateUrl: './connect-form.component.html',
  styleUrls: ['./connect-form.component.css'],
})
export class ConnectFormComponent implements OnInit {
  submitted = false;
  userEmail: any;
  firstName: any;
  lastName: any;
  addForm: UntypedFormGroup;
  get user_name() {
    return this.addForm.get('user_name');
  }
  get email() {
    return this.addForm.get('email');
  }
  get title() {
    return this.addForm.get('title');
  }
  get msg() {
    return this.addForm.get('msg');
  }

  constructor(
    private service: AdminService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.addForm = this.formBuilder.group({});
    this.userEmail = localStorage.getItem('email');
    this.firstName = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
  }

  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      user_name: new UntypedFormControl(
        this.firstName != null && this.lastName != null
          ? this.firstName + ' ' + this.lastName
          : '',
        [Validators.required]
      ),
      email: new UntypedFormControl(this.userEmail, [
        Validators.required,
        Validators.pattern(
          /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/
        ),
      ]),
      title: new UntypedFormControl('', [Validators.required]),
      msg: new UntypedFormControl('', [Validators.required]),
      created_by: localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : '',
    });
  }

  saveInfo() {
    this.submitted = true;
    if (this.addForm.invalid) {
      this.toastr.error('', 'Form is not valid');
      return;
    }
    let jsonObj: any = this.addForm.value;
    console.log(jsonObj, 4444);
    this.spinner.show();
    this.service.addMessage(jsonObj).subscribe(
      (sucess: boolean) => {
        this.spinner.hide();
        this.toastr.success('', 'Message sent successfully');
        this.addForm.reset();
        this.submitted = false;
        this.addForm.markAsPristine();
        this.addForm.markAsUntouched();
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error('', error);
      }
    );
  }
  get f() {
    return this.addForm.controls;
  }
}
