<form
  [formGroup]="registerdocForm"
  (ngSubmit)="docregister()"
  autocomplete="off"
>
  <div class="myContainer">
    <div class="form-container" [class]="activeStepIndex == 1 ? 'active' : ''">
      <div class="steps_main_content">
        <h3 class="dash_title">Personal Details</h3>

        <div class="col-lg-12 profile-pic">
          <div class="form-group">
            <div class="right_upld">
              <h5 class="para_required">
                Upload Profile Picture
                <!-- <span class="red-star">*</span> -->
              </h5>
              <mark
                >Permitted file formats are limited to JPG, GIF, JPEG or PNG.
              </mark>
            </div>
            <div>
              <label>
                <figure class="file-icon">
                  <img src="assets/images/upload_icon.png" alt="" />
                  <input
                    type="file"
                    formControlName="doc_file"
                    (change)="handleFileInput($event)"
                  />
                </figure>
              </label>
              <ul class="delete" *ngIf="fileToUpload">
                <li></li>
                <li>
                  <a (click)="reset($event)" class="img_delte">Delete</a>
                </li>
              </ul>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="submitted && f.doc_file.errors"
            >
              <div *ngIf="f.doc_file?.errors?.required">
                Please upload related document
              </div>
              <div *ngIf="f.doc_file?.invalid && !f.doc_file?.errors?.required">
                Please upload valid document
              </div>
            </div>
          </div>
        </div>
        <div class="common_form">
          <div class="row g-3">
            <div class="col-l-12">
              <div class="reqird">
                <ul class="radio_list">
                  <li *ngFor="let oTitle of titleLst">
                    <label class="radio_btn">
                      <input
                        type="radio"
                        formControlName="title"
                        value="{{ oTitle.title }}"
                        (change)="onChangeTitle($event)"
                      />
                      <span
                        ><mark>{{ oTitle.title }}</mark></span
                      >
                    </label>
                  </li>
                </ul>
                <span class="red-star">*</span>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.title.errors"
              >
                <div *ngIf="f.title?.errors?.required">Title is required</div>
              </div>
            </div>

            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                formControlName="first_name"
                placeholder="First Name"
                class="form-control"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.first_name.errors"
              >
                <div *ngIf="f.first_name.errors.required">
                  First Name is required
                </div>
                <div *ngIf="f.first_name.errors.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                placeholder="Last Name"
                formControlName="last_name"
                class="form-control"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.last_name.errors"
              >
                <div *ngIf="f.last_name?.errors?.required">
                  Last Name is required
                </div>
                <div *ngIf="f.last_name?.errors?.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="red-star">*</span>
                <input
                  formControlName="profession_statement"
                  type="text"
                  class="form-control"
                  id="profession_statement"
                  placeholder="Profession Statement"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="submitted && f.profession_statement.errors"
                >
                  <div *ngIf="f.profession_statement?.errors?.required">
                    Profession statement is required
                  </div>
                  <div *ngIf="f.profession_statement.errors.pattern">
                    Input cannot contain only whitespace characters.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="red-star">*</span>
                <div class="input-group">
                  <input
                    formControlName="date_of_birth"
                    type="text"
                    class="form-control"
                    id="date_of_birth"
                    placeholder="DOB"
                    ngbDatepicker
                    #dob="ngbDatepicker"
                    [minDate]="{ year: 1930, month: 1, day: 1 }"
                    [maxDate]="maxDate"
                    (click)="dob.toggle()"
                  />

                  <div class="input-group-append">
                    <button
                      class="calendar"
                      (click)="dob.toggle()"
                      type="button"
                    >
                      <img src="assets/images/caldr_icon.png" alt="" />
                      <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                    </button>
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="submitted && f.date_of_birth.errors"
                >
                  <div *ngIf="f.date_of_birth?.errors?.required">
                    DOB is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-l-12">
              <div class="reqird">
                <ul class="radio_list">
                  <li *ngFor="let oGender of genderLst">
                    <label class="radio_btn">
                      <input
                        type="radio"
                        formControlName="gender"
                        value="{{ oGender.gender_id }}"
                        (change)="onChangeGender($event)"
                      />
                      <span
                        ><mark>{{ oGender.gender }}</mark></span
                      >
                    </label>
                  </li>
                </ul>
                <span class="red-star">*</span>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.gender.errors"
              >
                <div *ngIf="f.gender?.errors?.required">Gender is required</div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                placeholder="Address 1"
                class="form-control"
                formControlName="address"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.address.errors"
              >
                <div *ngIf="f.address?.errors?.required">
                  Address is required
                </div>
                <div *ngIf="f.address.errors.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <input type="text" placeholder="Address 2" class="form-control" />
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="number"
                pattern="[0-9]*"
                placeholder="Pin/Zip"
                formControlName="zip_code"
                class="form-control"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.zip_code.errors"
              >
                <div *ngIf="f.zip_code?.errors?.required">
                  Zip/Pin is required
                </div>
                <div *ngIf="f.zip_code?.errors?.pattern">
                  Please enter a valid Pin/Zip code
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <select
                class="form-control form-select"
                formControlName="country_id"
                id="country_id"
                (change)="changeCountry($event)"
              >
                <option
                  *ngFor="let oCountry of countryLst"
                  [value]="oCountry.country_id"
                >
                  {{ oCountry.country }}
                </option>
              </select>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.country_id.errors"
              >
                <div *ngIf="country_id?.errors?.required">
                  Please select country
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <select
                class="form-control"
                formControlName="state_id"
                id="state_id"
              >
                <option
                  *ngFor="let oState of stateLst"
                  [value]="oState.state_id"
                >
                  {{ oState.state }}
                </option>
              </select>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.state_id.errors"
              >
                <div *ngIf="f.state_id?.errors?.required">
                  Please select state
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                placeholder="City"
                class="form-control"
                formControlName="city"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.city.errors"
              >
                <div *ngIf="f.city?.errors?.required">City is required</div>
                <div *ngIf="f.city?.errors?.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-lg-3 position-relative">
                  <span class="red-star">*</span>
                  <select class="form-select" formControlName="phone_iso">
                    <option
                      data-countryCode="country.iso"
                      [value]="country.code"
                      *ngFor="let country of countryCodeLst"
                    >
                      {{ country.country }} (+{{ country.code }})
                    </option>
                  </select>
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.phone_iso.errors"
                  >
                    <div *ngIf="f.phone_iso?.errors?.required">
                      Country Code is required
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 position-relative">
                  <span class="red-star">*</span>
                  <input
                    type="number"
                    placeholder="Phone Number"
                    class="form-control"
                    formControlName="phone"
                  />
                  <div *ngIf="phone?.pending">Checking for unique...</div>
                  <div
                    class="alert alert-danger"
                    *ngIf="submitted && f.phone.errors"
                  >
                    <div *ngIf="f.phone?.errors?.required">
                      Phone is required
                    </div>
                    <div *ngIf="phone?.errors?.pattern" class="text-danger">
                      Please provide a valid phone number
                    </div>

                    <div *ngIf="phone?.errors?.phoneExist">
                      Phone is already taken
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                placeholder="E-mail ID"
                class="form-control"
                formControlName="email"
                style="text-transform: none"
              />
              <div *ngIf="email?.pending">Checking for unique...</div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.email.errors"
              >
                <div *ngIf="f.email?.errors?.required">Email is required</div>
                <div *ngIf="f.email.errors.pattern">
                  Input cannot contain only whitespace characters.
                </div>
                <div *ngIf="email?.errors?.email">Email is not valid</div>
                <div *ngIf="email?.errors?.emailExist">
                  Email is already taken
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <div class="passwrd_field">
                <input
                  formControlName="password"
                  [type]="orgPasswordFlg ? 'password' : 'text'"
                  placeholder="Password"
                  class="form-control"
                />
                <div class="input-group-append">
                  <button
                    class="calendar"
                    type="button"
                    (click)="showHidePassword()"
                  >
                    <i
                      [class]="orgPasswordFlg ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.password.errors"
              >
                <div *ngIf="f.password?.errors?.required">
                  Password is required
                </div>
                <div *ngIf="password?.errors?.pattern" class="red-star">
                  Please follow the below criteria
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <div class="passwrd_field">
                <input
                  formControlName="confirmPassword"
                  [type]="confirmPasswordFlg ? 'password' : 'text'"
                  placeholder="Confirm Password"
                  class="form-control"
                />
                <div class="input-group-append">
                  <button
                    class="calendar"
                    type="button"
                    (click)="showHideConfirmPassword()"
                  >
                    <i
                      [class]="
                        confirmPasswordFlg ? 'fa fa-eye-slash' : 'fa fa-eye'
                      "
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.confirmPassword.errors"
              >
                <div *ngIf="f.confirmPassword?.errors?.required">
                  Confirm password is required
                </div>
                <div *ngIf="confirmPassword?.errors?.confirmedValidator">
                  Passwords don't match.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-12"> -->
        <div class="fs-6 mt-3 criteria_of_form">
          <h6 class="fw-semibold mb-2">
            The password must meet the following criteria:
          </h6>
          <ol class="ps-0 blue-txt fw-light h6">
            <li>1. It should be a minimum of 8 characters long.</li>
            <li>2. It must include at least one uppercase letter.</li>
            <li>3. It must include at least one lowercase letter.</li>
            <li>4. It must include at least one number.</li>
            <li>5. It must include at least one special character.</li>
          </ol>
        </div>
        <!-- </div> -->
      </div>
      <div class="text-end steps_btns">
        <ul>
          <li>
            <span class="next yellow_btn" (click)="onNextClick($event)"
              >NEXT</span
            >
          </li>
        </ul>
      </div>
      <!-- <button type="submit">Submit</button> -->
    </div>
    <div class="form-container" [class]="activeStepIndex === 2 ? 'active' : ''">
      <div class="steps_main_content">
        <h3 class="dash_title">Professional Details</h3>
        <div class="common_form">
          <div class="row g-3 align-items-center">
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="Highest Qualification"
                formControlName="highest_qualification"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.highest_qualification.errors"
              >
                <div *ngIf="f.highest_qualification?.errors?.required">
                  Highest Qualification is required
                </div>
                <div *ngIf="f.highest_qualification?.errors?.pattern">
                  Invalid input for Highest Qualification
                </div>
              </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="Graduation in"
                formControlName="graduation_in"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.graduation_in.errors"
              >
                <div *ngIf="f.graduation_in?.errors?.required">
                  Graduation Year is required
                </div>
                <div *ngIf="f.graduation_in?.errors?.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="University/Institute"
                formControlName="university_institute"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.university_institute.errors"
              >
                <div *ngIf="f.university_institute?.errors?.required">
                  University/Institute is required
                </div>
                <div *ngIf="f.university_institute?.errors?.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Post-Graduation (if any)"
                formControlName="post_graduation"
              />
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Research/Publication (if any)"
                formControlName="research"
              />
            </div>
            <div class="col-lg-12">
              <div class="reqird">
                <ul class="radio_list">
                  <li>
                    Physician
                    <span class="red-star">*</span>
                  </li>
                  <!-- <li>
                    <label class="radio_btn">
                      <input type="radio" name="demo2" />
                      <span><mark>Yes</mark></span>
                    </label>
                  </li>
                  <li>
                    <label class="radio_btn">
                      <input type="radio" name="demo2" />
                      <span><mark>No</mark></span>
                    </label>
                  </li>  -->
                  <li *ngFor="let status of physicianLst">
                    <label class="radio_btn">
                      <input
                        type="radio"
                        formControlName="physician"
                        value="{{ status.value }}"
                        (change)="onChangeStatus($event)"
                      />
                      <span
                        ><mark>{{ status.available }}</mark></span
                      >
                    </label>
                  </li>
                </ul>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.physician.errors"
              >
                <div *ngIf="f.physician?.errors?.required">
                  Please Select one
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Professional Diploma/Certificate"
                formControlName="certificate"
              />
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Other Degrees/Qualifications"
                formControlName="qualifications"
              />
            </div>
            <div class="col-lg-6">
              <div class="reqird">
                <div class="para_required">
                  <p>
                    Which language(s) are you fluent in?
                    <span class="red-star">*</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <ul class="language_check">
                <li *ngFor="let language of languageLst">
                  <label class="lang_chek_box">
                    <input
                      type="checkbox"
                      formControlName="language"
                      [value]="language.value"
                      (change)="onChangeLanguage($event)"
                    />
                    <span>{{ language.name }}</span>
                  </label>
                </li>
                <li>
                  <!-- Button to toggle input field visibility -->
                  <a
                    href="javascript:void(0)"
                    class="pluse"
                    (click)="toggleInputFieldLang()"
                    >+</a
                  >
                  <form
                    [formGroup]="newLanguageForm"
                    (ngSubmit)="addLanguage($event)"
                  >
                    <!-- Input field to add new language -->
                    <input
                      *ngIf="showInputFieldLang"
                      type="text"
                      [formControl]="newLanguageControl"
                      (keyup.enter)="addLanguage($event)"
                      class="input-field"
                    />
                  </form>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="alert alert-danger"
            *ngIf="submitted && f.language.errors"
          >
            <div *ngIf="f.language?.errors?.required">
              Please Select Language(s)
            </div>
          </div>
        </div>
      </div>
      <div class="text-end steps_btns">
        <ul>
          <li>
            <!-- <a href="#url" class="back yellow_btn">PREV</a> -->
            <span class="back yellow_btn" (click)="onBackPress($event)"
              >PREV</span
            >
          </li>
          <li>
            <!-- <a href="#url" class="next yellow_btn">NEXT</a> -->
            <span class="next yellow_btn" (click)="onNextClick($event)"
              >NEXT</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="form-container" [class]="activeStepIndex == 3 ? 'active' : ''">
      <div class="steps_main_content">
        <h3 class="dash_title">Practice Details</h3>
        <div class="practice_box">
          <span class="red-star practice">*</span>
          <textarea
            class="form-control"
            formControlName="practice_details"
            id="exampleFormControlTextarea1"
            placeholder=" Get yourself introduced to your clients. Write about you in a third persons voice."
            rows="12"
          ></textarea>

          <div
            class="alert alert-danger"
            *ngIf="submitted && f.practice_details.errors"
          >
            <div *ngIf="f.practice_details?.errors?.required">
              Practice Details is required
            </div>
            <div *ngIf="f.practice_details?.errors?.pattern">
              Input cannot contain only whitespace characters.
            </div>
          </div>
        </div>
        <div class="common_form mt-3">
          <div class="row g-3 align-items-center">
            <div class="col-lg-4">
              <span class="red-star">*</span>
              <!-- <input
                type="text"
                class="datepicker"
                class="form-control"
                placeholder="Practicing from"
              /> -->
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Practicing form"
                  name="practicing_from"
                  formControlName="practicing_from"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  [minDate]="{ year: 1930, month: 1, day: 1 }"
                  [maxDate]="maxDate"
                  (click)="d.toggle()"
                />
                <div class="input-group-append">
                  <button class="calendar" (click)="d.toggle()" type="button">
                    <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                    <img src="assets/images/caldr_icon.png" alt="" />
                  </button>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.practicing_from.errors"
              >
                <div *ngIf="f.practicing_from?.errors?.required">
                  Practing from is required
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <span class="red-star">*</span>
              <input
                type="number"
                placeholder="Consultation fee"
                formControlName="consultation_fee"
                class="form-control"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.consultation_fee.errors"
              >
                <div *ngIf="f.consultation_fee?.errors?.required">
                  Consultation fee is required
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <span class="red-star">*</span>
              <input
                type="number"
                placeholder="Time slot / client (in min)"
                class="form-control"
                formControlName="time_slot_per_patient_in_min"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.time_slot_per_patient_in_min.errors"
              >
                <div *ngIf="f.time_slot_per_patient_in_min?.errors?.required">
                  Time slot is required
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="para_required">
                <p>
                  General availability to see clients
                  <span class="red-star">*</span>
                </p>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <ul class="daynight_list">
                <li>MON-FRI</li>
                <!-- <li>
                  <label class="daynight_radio">
                    <input type="radio" name="dem" />
                    <span><img src="assets/images/sun_icon.png" alt="" /></span>
                  </label>
                </li>
                <li>
                  <label class="daynight_radio">
                    <input type="radio" name="dem" />
                    <span
                      ><img src="assets/images/moon_icon.png" alt=""
                    /></span>
                  </label>
                </li> -->

                <li *ngFor="let availability of weekdayLst">
                  <label class="daynight_radio">
                    <input
                      type="radio"
                      formControlName="general_availability_weekday"
                      value="{{ availability.value }}"
                      (change)="
                        onChangeGeneralAvailabilityWeekdayStatus($event)
                      "
                    />
                    <span
                      ><img src="{{ availability.imagesrc }}" alt=""
                    /></span>
                  </label>
                </li>
              </ul>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.general_availability_weekday.errors"
              >
                <div *ngIf="f.general_availability_weekday?.errors?.required">
                  Please Select One
                </div>
              </div>
            </div>

            <div class="col-md-4 col-6">
              <ul class="daynight_list">
                <li>SAT-SUN</li>
                <!-- <li>
                  <label class="daynight_radio">
                    <input type="radio" name="de" />
                    <span><img src="assets/images/sun_icon.png" alt="" /></span>
                  </label>
                </li>
                <li>
                  <label class="daynight_radio">
                    <input type="radio" name="de" />
                    <span
                      ><img src="assets/images/moon_icon.png" alt=""
                    /></span>
                  </label>
                </li> -->

                <li *ngFor="let availability of weekendLst">
                  <label class="daynight_radio">
                    <input
                      type="radio"
                      formControlName="general_availability_weekend"
                      value="{{ availability.value }}"
                      (change)="
                        onChangeGeneralAvailabilityWeekendStatus($event)
                      "
                    />
                    <span
                      ><img src="{{ availability.imagesrc }}" alt=""
                    /></span>
                  </label>
                </li>
              </ul>
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.general_availability_weekend.errors"
              >
                <div *ngIf="f.general_availability_weekend?.errors?.required">
                  Please Select One
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="para_required mb-3">
                <p>
                  Area(s) you have experience and specialize in
                  <span class="red-star">*</span>
                </p>
              </div>
              <ul class="language_check">
                <li *ngFor="let specialization of specializationLst">
                  <label class="lang_chek_box">
                    <input
                      type="checkbox"
                      formControlName="specializations"
                      [value]="specialization.value"
                      (change)="onChangeSpecialization($event)"
                    />
                    <span>{{ specialization.name }}</span>
                  </label>
                </li>
                <li>
                  <!-- Button to toggle input field visibility -->
                  <a
                    href="javascript:void(0)"
                    class="pluse"
                    (click)="toggleInputFieldSpecialization()"
                    >+</a
                  >
                  <form
                    [formGroup]="newSpecializationForm"
                    (ngSubmit)="addSpecialization($event)"
                  >
                    <!-- Input field to add new specialization -->
                    <input
                      *ngIf="showInputFieldSpecialization"
                      type="text"
                      [formControl]="newSpecializationControl"
                      (keyup.enter)="addSpecialization($event)"
                      class="input-field"
                    />
                  </form>
                </li>
              </ul>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="submitted && f.specializations.errors"
            >
              <div *ngIf="f.specializations?.errors?.required">
                Please Select One
              </div>
            </div>

            <div class="col-lg-12">
              <div class="para_required mb-3">
                <p>
                  The therapies you offer
                  <span class="red-star">*</span>
                </p>
              </div>
              <ul class="language_check">
                <li *ngFor="let offer of offerLst">
                  <label class="lang_chek_box">
                    <input
                      type="checkbox"
                      formControlName="therapies_offer"
                      [value]="offer.value"
                      (change)="onChangeOffer($event)"
                    />
                    <span>{{ offer.name }}</span>
                  </label>
                </li>
                <li>
                  <!-- Button to toggle input field visibility -->
                  <a
                    href="javascript:void(0)"
                    class="pluse"
                    (click)="toggleInputFieldOffer()"
                    >+</a
                  >
                  <form
                    [formGroup]="newOfferForm"
                    (ngSubmit)="addOffer($event)"
                  >
                    <!-- Input field to add new offer -->
                    <input
                      *ngIf="showInputFieldOffer"
                      type="text"
                      [formControl]="newOfferControl"
                      (keyup.enter)="addOffer($event)"
                      class="input-field"
                    />
                  </form>
                </li>
              </ul>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="submitted && f.therapies_offer.errors"
            >
              <div *ngIf="f.therapies_offer?.errors?.required">
                Please Select One
              </div>
            </div>

            <div class="col-lg-12 mention_part">
              <label class="para_required">
                Please mention your affiliations
                <span class="red-star">*</span>
              </label>
              <mark> Separate by comma.</mark>
              <input
                type="text"
                class="form-control"
                formControlName="affiliations"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.affiliations.errors"
              >
                <div *ngIf="f.affiliations?.errors?.required">
                  Affiliations are required
                </div>
                <div *ngIf="f.affiliations?.errors?.pattern">
                  Input cannot contain only whitespace characters.
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <ul class="radio_list">
                <li class="para_required">
                  Do you provide couples or family counselling?
                  <span class="red-star">*</span>
                </li>
                <!-- <li>
                  <label class="radio_btn">
                    <input type="radio" name="ded" />
                    <span><mark>Yes</mark></span>
                  </label>
                </li>
                <li>
                  <label class="radio_btn">
                    <input type="radio" name="ded" />
                    <span><mark>No</mark></span>
                  </label>
                </li> -->
                <li *ngFor="let status of familyCounsellingLst">
                  <label class="radio_btn">
                    <input
                      type="radio"
                      formControlName="family_counselling"
                      value="{{ status.value }}"
                      (change)="onChangeCounsellingStatus($event)"
                    />
                    <span
                      ><mark>{{ status.available }}</mark></span
                    >
                  </label>
                </li>
              </ul>
            </div>
            <div
              class="alert alert-danger"
              *ngIf="submitted && f.family_counselling.errors"
            >
              <div *ngIf="f.family_counselling?.errors?.required">
                Please Select One
              </div>
            </div>
            <div class="col-lg-12">
              <p class="para_required">
                What is your approach to care? Select top three that most apply
                to your practice.
                <span class="red-star">*</span>
              </p>
              <ul class="language_check">
                <!-- <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Professional and personal goal setting</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Time and task management strategies</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span
                      >The impact of thoughts on emotions and behaviours</span
                    >
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Trauma and chaos of the past</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Moral, spiritual, and religious influences</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Mindfulness and emotional acceptance</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Practical ways of coping with life</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Relationship health and life transitions</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Deep-rooted feelings and impulses</span>
                  </label>
                </li> -->

                <li *ngFor="let approach of approachLst">
                  <label class="lang_chek_box">
                    <input
                      type="checkbox"
                      formControlName="approach_to_care"
                      [value]="approach.value"
                      (change)="onChangeApproach($event)"
                    />
                    <span>{{ approach.name }}</span>
                  </label>
                </li>

                <!-- <li>
                  <a href="#url" class="pluse">+</a>
                </li> -->
              </ul>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="submitted && f.approach_to_care.errors"
            >
              <div *ngIf="f.approach_to_care?.errors?.required">
                Please Select At Least Three Option
              </div>
            </div>
            <div class="col-lg-12">
              <p class="para_required">
                What age group(s) do you feel comfortable counselling?
                <span class="red-star">*</span>
              </p>
              <ul class="language_check">
                <!-- <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Children</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Preteen (10-13 years)</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Adolescents (15-19 years)</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Adults (20-64 years)</span>
                  </label>
                </li>
                <li>
                  <label class="lang_chek_box">
                    <input type="checkbox" />
                    <span>Seniors (65+ years)</span>
                  </label>
                </li> -->

                <li *ngFor="let age of agegroupLst">
                  <label class="lang_chek_box">
                    <input
                      type="checkbox"
                      formControlName="age_group"
                      [value]="age.value"
                      (change)="onChangeAgegroup($event)"
                    />
                    <span>{{ age.name }}</span>
                  </label>
                </li>

                <!-- <li>
                  <a href="#url" class="pluse">+</a>
                </li> -->
              </ul>
            </div>
            <div
              class="alert alert-danger"
              *ngIf="submitted && f.age_group.errors"
            >
              <div *ngIf="f.age_group?.errors?.required">Please Select One</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end steps_btns">
        <ul>
          <li>
            <!-- <a href="#url" class="back yellow_btn">PREV</a> -->
            <span class="back yellow_btn" (click)="onBackPress($event)"
              >PREV</span
            >
          </li>
          <li>
            <!-- <a href="#url" class="next yellow_btn">NEXT</a> -->
            <span class="next yellow_btn" (click)="onNextClick($event)"
              >NEXT</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="form-container" [class]="activeStepIndex == 4 ? 'active' : ''">
      <div class="steps_main_content">
        <h3 class="dash_title">Required Documents</h3>
        <!-- <div class="upload_profile">
          <label class="upload_file">
            <figure>
              <img src="assets/images/file_icon2.png" alt="" />
            </figure>
            <input
              type="file"
              formControlName="uploader_field"
              id="file"
              (change)="handleFileInput($event)"
              class="img_uploadfile"
            />

            <div class="right_upld">
              <h5 class="para_required">
                Upload Professional license/certification
                <span class="red-star">*</span>
              </h5>
              <mark
                >Permitted file formats are limited to JPG, JPEG, PNG, PDF, DOC,
                DOCX with a maximum file size of 2MB.</mark
              >
            </div>
            <div
              class="alert alert-danger"
              *ngIf="submitted && f.uploader_field.errors"
            >
              <div *ngIf="f.uploader_field?.errors?.required">
                Please upload related document
              </div>
            </div>
          </label>

          <ul class="upload_action">
            <li class="images_path"></li>
            <li><a href="#url" class="img_delte">Delete</a></li>
          </ul>
        </div> -->

        <div class="col-lg-12">
          <div class="form-group">
            <div class="right_upld">
              <h5 class="para_required">
                Upload Professional license/certification
                <span class="red-star">*</span>
              </h5>
              <mark
                >Permitted file formats are limited to JPG, JPEG, PNG, PDF, DOC,
                DOCX.</mark
              >
            </div>
            <div>
              <label>
                <figure class="file-icon">
                  <img src="assets/images/upload_icon.png" alt="" />
                  <input
                    type="file"
                    multiple
                    formControlName="uploader_field"
                    (change)="handleFileInputTherepist($event)"
                  />
                </figure>
              </label>

              <div *ngFor="let file of fileToUploadTherapist">
                {{ file.name }}
              </div>

              <ul class="delete mt-2" *ngIf="fileToUploadTherapist">
                <li></li>
                <li>
                  <a (click)="resetCertificate($event)" class="img_delte"
                    >Delete</a
                  >
                </li>
              </ul>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="submitted && f.uploader_field.errors"
            >
              <div *ngIf="f.uploader_field.errors.required">
                Please upload a document.
              </div>
              <div
                *ngIf="
                  f.uploader_field.errors.invalid &&
                  !f.uploader_field.errors.required
                "
              >
                Please upload a valid document.
              </div>
              <!-- <div
                *ngIf="
                  fileToUploadTherapist &&
                  fileToUploadTherapist.length > maxSizeInBytes
                "
              >
                File size exceeds 2 MB. Please choose a smaller file.
              </div> -->
              <div *ngIf="certificationValid">
                Invalid file type. Please choose a JPG, JPEG, PNG, PDF, DOC or
                DOCX file.
              </div>
            </div>
          </div>
        </div>

        <div class="troubeling_part">
          <label>
            <input type="checkbox" formControlName="trouble_uploading_docs" />
            <p>
              If you are having troubles uploading documents, please email them
              to <br />
              <a href="mailto:info@careocure.com">info@careocure.com</a>, and
              check this box.
            </p>
          </label>
        </div>
      </div>

      <div class="text-end steps_btns">
        <ul>
          <li>
            <!-- <a href="#url" class="back yellow_btn">PREV</a> -->
            <span class="back yellow_btn" (click)="onBackPress($event)"
              >PREV</span
            >
          </li>
          <li>
            <!-- <a href="#url" class="next yellow_btn">NEXT</a> -->
            <span class="next yellow_btn" (click)="onNextClick($event)"
              >NEXT</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="form-container" [class]="activeStepIndex == 5 ? 'active' : ''">
      <div class="steps_main_content">
        <h3 class="dash_title">Payment Information</h3>
        <div class="common_form">
          <div class="row g-3">
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="Name as per bank record"
                formControlName="name_in_bank"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.name_in_bank.errors"
              >
                <div *ngIf="f.name_in_bank.errors.pattern">
                  Invalid input for Name In Bank
                </div>
                <div *ngIf="f.name_in_bank?.errors?.required">
                  Name In Bank is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="number"
                class="form-control"
                placeholder="Account Number"
                formControlName="account_number"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.account_number.errors"
              >
                <div *ngIf="f.account_number?.errors?.required">
                  Account Number is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="PAN Number"
                formControlName="pan_number"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.pan_number.errors"
              >
                <div *ngIf="f.pan_number?.errors?.required">
                  PAN number is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="Name of the Bank"
                formControlName="bank_name"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.bank_name.errors"
              >
                <div *ngIf="f.bank_name.errors.pattern">
                  Invalid input for Bank Name
                </div>
                <div *ngIf="f.bank_name?.errors?.required">
                  Bank Name is required
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <span class="red-star">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="IFSC Code"
                formControlName="ifsc_code"
              />
              <div
                class="alert alert-danger"
                *ngIf="submitted && f.ifsc_code.errors"
              >
                <div *ngIf="f.ifsc_code?.errors?.required">
                  IFSC Code is required
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <!-- <span
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </span> -->
            </div>
            <div class="col-lg-12 terms_use mt-4">
              <h3 class="dash_title">Terms of Use</h3>
              <div class="troubeling_part mb-3">
                <label>
                  <input
                    type="checkbox"
                    formControlName="will_receive_news"
                    id="will_receive_news"
                  />
                  <p>
                    I agree to receive relevant offers and promotional
                    communication from Care O Cure.
                  </p>
                </label>
              </div>
              <div class="troubeling_part">
                <label>
                  <input
                    type="checkbox"
                    formControlName="termAndCondition"
                    id="termAndCondition"
                  />
                  <p>
                    I have read and agree to all the
                    <a href="terms-and-conditions" target="_blank"
                      >Terms &amp; Conditions</a
                    >
                    of Care O Cure.
                  </p>
                </label>
                <div
                  class="alert alert-danger"
                  *ngIf="submitted && f.termAndCondition.errors"
                >
                  <div *ngIf="f.termAndCondition?.errors?.required">
                    please agree with terms and condition
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end steps_btns">
        <ul>
          <li>
            <!-- <a href="#url" class="back yellow_btn">PREV</a> -->
            <span class="back yellow_btn" (click)="onBackPress($event)"
              >PREV</span
            >
          </li>
          <li>
            <button type="submit" class="yellow_btn">Register</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>

<ngx-spinner type="ball-spin ">
  <p style="color: white">
    <img
      src="assets/images/loader.gif"
      width="60"
      height="60"
      alt="loading..."
    />
  </p>
</ngx-spinner>
