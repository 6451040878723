<section
  class="info_sec p-0"
  style="background: url(assets/images/login_bnr.jpg) no-repeat 0 0 / cover"
>
  <div class="container">
    <div class="forget_iner">
      <div
        class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 align-items-center"
      >
        <div class="col order-lg-1 order-2">
          <img src="assets/images/login_img.svg" alt="" />
        </div>
        <div class="col order-lg-2 order-1">
          <form
            class="common_form info_form"
            [formGroup]="changePasswordForm"
            (ngSubmit)="changePasword()"
            autocomplete="off"
          >
            <h3 class="normal_head">Forgot Password</h3>
            <div class="error_holder">
              <label>Email Address</label>
              <div class="filed_box">
                <input
                  type="email"
                  placeholder="Email"
                  class="form-control"
                  formControlName="email"
                />
              </div>
              <div
                class="alert alert-danger error_msg"
                *ngIf="email?.touched && !email?.valid"
              >
                <div *ngIf="email?.errors?.required">Email is required</div>
                <div *ngIf="email?.errors?.email">Email is not valid</div>
                <div *ngIf="email?.errors?.pattern">
                  Invalid input for Email
                </div>
              </div>
              <div class="submit_form_btn">
                <input type="submit" value="Submit" class="submit_btn" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner type="ball-spin">
  <p style="color: white">
    <img
      src="assets/images/loader.gif"
      width="60"
      height="60"
      alt="loading..."
    />
  </p>
</ngx-spinner>
