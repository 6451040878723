import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, retry, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  private baseurl = '';
  constructor(private http: HttpClient) {
    this.baseurl = environment.api_baseUrl;
  }

  getDoctorAvilabilities(authToken: string, doctor_id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/availabilities/get/' + doctor_id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(
            'Encountered some issue. Please contact system administrator.'
          );
        }),
        map((result: any) => {
          return result.datalist;
        })
      );
  }

  saveDoctorAvilabilities(authToken: string, jsonData: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: authToken,
    });
    console.log(authToken);

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/availabilities/save',
        JSON.stringify(jsonData),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(
            'Encountered some issue. Please contact system administrator.'
          );
        }),
        map((result: any) => {
          if (result.error) {
            throw new Error(result.messages);
          } else {
            return result.messages;
          }
        })
      );
  }

  deleteDoctorAvilability(authToken: string, availability_id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .delete(this.baseurl + '/api/availabilities/delete/' + availability_id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(
            'Encountered some issue. Please contact system administrator.'
          );
        }),
        map((result: any) => {
          return true;
        })
      );
  }
}
