<router-outlet></router-outlet>
<ngx-spinner type="ball-spin">
  <h1 class="visually-hidden">
    <p style="color: white">
      <img
        src="assets/images/loader.gif"
        width="60"
        height="60"
        alt="loading..."
      />
    </p>
  </h1>
</ngx-spinner>
