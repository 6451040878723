<div class="main-wrapper login-body">
  <div class="login-wrapper">
    <div class="container">
      <div class="loginbox">
        <div class="login-left">
          <img class="img-fluid" src="assets/images/logo1.png" alt="Logo" />
        </div>
        <div class="login-right">
          <div class="login-right-wrap">
            <h1 class="account-subtitle">Access to Administrator Panel</h1>
            <!-- Form -->
            <form
              [formGroup]="loginForm"
              (ngSubmit)="signIn()"
              autocomplete="off"
              style="font-size: 0.9em"
              class="form-signin"
            >
              <div class="form-group">
                <label for="username">Email address</label>
                <input
                  type="email"
                  formControlName="username"
                  class="form-control"
                  placeholder="Email Address"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="username?.touched && !username?.valid"
                >
                  <div *ngIf="username?.errors?.required">
                    Enter email/phone
                  </div>
                </div>
              </div>

              <!-- <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" 
                          id="password" 
                          formControlName="password" 
                          class="form-control" placeholder="Password">
                        
                        <div class="alert alert-danger" *ngIf="password?.touched && !password?.valid">
                          <div *ngIf="password?.errors?.required">Enter Password</div>
                        </div>
                      </div> -->

              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <input
                    type="password"
                    id="password"
                    formControlName="password"
                    [type]="passwordFlg ? 'password' : 'text'"
                    class="form-control"
                    placeholder="Password(Minimum 8 chracters)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      type="button"
                      (click)="showHidePassword()"
                    >
                      <i
                        [class]="passwordFlg ? 'fa fa-eye-slash' : 'fa fa-eye'"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="password?.touched && !password?.valid"
                >
                  <div *ngIf="password?.errors?.required">Enter Password</div>
                </div>
              </div>

              <div class="form-group">
                <div class="p-2">
                  <label class="form-check-label" style="padding-left: 10px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      formControlName="rememberMe"
                    />Remember me
                  </label>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-spin"
  ><p style="color: white">
    <img
      src="assets/images/loader.gif"
      width="60"
      height="60"
      alt="loading..."
    /></p
></ngx-spinner>
