import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  year: any;
  windowScrolled = false;
  constructor() {
    this.year = new Date().getFullYear();
  }

  ngOnInit(): void {
    console.log(this.baseUrl);
    console.log('---------YEAR------', this.year);
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset >= 1000;
    });
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
