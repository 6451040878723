<header class="site-header">
  <div class="container-fluid">
    <div class="header-hldr d-flex align-items-center">
      <h1>
        <a routerLink="/home" class="logo" title="Care-O-Cure"
          ><img src="assets/images/logo.png" alt="Logo"
        /></a>
      </h1>
      <div class="main-menu">
        <nav>
          <ul>
            <li>
              <a routerLink="/home" [routerLinkActive]="['active']">Home</a>
            </li>
            <li>
              <a routerLink="/about-us" [routerLinkActive]="['active']"
                >About</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/therapy-seekers']"
                [queryParams]="{ tab: 'individuals' }"
                routerLinkActive="active"
                >Therapy Seekers</a
              >
            </li>
            <li>
              <a routerLink="/therapists" [routerLinkActive]="['active']"
                >Therapists</a
              >
            </li>
            <!-- <li><a routerLink="practitioners">Practitioners </a></li> -->
          </ul>
        </nav>

        <!-- <a
          class="mobilemenuBtn"
          href="#url"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span></span>
          <span></span>
          <span></span>
        </a> -->
      </div>
      <div class="userlogin">
        <p>WELCOME</p>
        <h5>{{ loggedUserName }}</h5>
        <span>THERAPY SEEKER</span>
        <ul>
          <li><a routerLink="home">Your DASHBOARD</a></li>
          <li><a routerLink="/logout">LOG OUT</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
