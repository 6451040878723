<!-- Sidebar -->
<div class="sidebar" id="sidebar">
  <div
    class="slimScrollDiv"
    style="position: relative; overflow: hidden; width: 100%; height: 540px"
  >
    <div
      class="sidebar-inner slimscroll"
      style="overflow: hidden; width: 100%; height: 540px"
    >
      <div id="sidebar-menu" class="sidebar-menu">
        <ul>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData &&
              rolePermissionData['dashboard-appointment-list']
            "
          >
            <a routerLink="home"
              ><i class="fe fe-home"></i> <span>Dashboard</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['user-list']"
          >
            <a routerLink="user-management"
              ><i class="fe fe-users"></i> <span>User Management</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['role-list']"
          >
            <a routerLink="roles-permissions"
              ><i class="fe fe-document"></i> <span>Role Management</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['counsellor-list']"
          >
            <a routerLink="therapists"
              ><i class="fe fe-user-plus"></i>
              <span>Counsellors/Therapists</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['therapy-seeker-list']
            "
          >
            <a routerLink="therapy-seekers"
              ><i class="fe fe-user"></i> <span>Therapy Seekers</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['cms-page-list']"
          >
            <a routerLink="cms-pages"
              ><i class="fe fe-document"></i> <span>CMS Pages</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['appointment-list']"
          >
            <a routerLink="appointments"
              ><i class="fe fe-layout"></i> <span>Appointments</span></a
            >
          </li>
          <li routerLinkActive="active current">
            <a routerLink="cancelled-appointments"
              ><i class="fe fe-layout"></i>
              <span>Cancelled Appointments</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['order-history-list']
            "
          >
            <a routerLink="order-history"
              ><i class="fe fe-info"></i> <span>Order History</span></a
            >
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['gift-session-list']
            "
          >
            <a routerLink="gift-session"
              ><i class="fe fe-gift"></i> <span>Gift Session</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['purchased-gift-list']
            "
          >
            <a routerLink="purchased-gift-listing"
              ><i class="fe fe-document"></i> <span>Purchased Gifts</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['coupon-list']"
          >
            <a routerLink="coupon-code"
              ><i class="fe fe-document"></i> <span>Coupons</span></a
            >
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['coupon-list']"
          >
            <a routerLink="testimonial-list"
              ><i class="fe fe-document"></i> <span>Testimonials</span></a
            >
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['mood-report-list']"
          >
            <a routerLink="mood-tracking-list"
              ><i class="fe fe-document"></i> <span>Mood Reports</span></a
            >
          </li>

          <li
            class="dropdown-list"
            [ngClass]="{ active: dropdownClicked }"
            ngbDropdown
            *ngIf="hasDropdownPermissions()"
          >
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="gfg"
              ngbDropdownToggle
            >
              <i class="far fa-file"></i> <span>Manage Assessment</span>
            </a>

            <div ngbDropdownMenu="gfg" (click)="closeDropdown()">
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['severity-master-list']
                "
                routerLink="severity"
              >
                <i class="fas fa-exclamation-triangle"></i> Severity Master
              </a>
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['question-category-list']
                "
                routerLink="question-category"
              >
                <i class="fas fa-list-ul"></i> Question Category
              </a>
              <a
                *ngIf="
                  rolePermissionData && rolePermissionData['question-list']
                "
                routerLink="user-assessment"
              >
                <i class="far fa-file"></i> Manage Questions
              </a>
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['assessment-sheet-list']
                "
                routerLink="question-sheet"
              >
                <i class="far fa-file-alt"></i> Assessment Sheet
              </a>
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['assessment-results-list']
                "
                routerLink="assessment-list"
              >
                <i class="far fa-file-alt"></i> Assessment Results
              </a>
            </div>
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['blog-list']"
          >
            <a routerLink="blog"
              ><i class="fe fe-document"></i> <span>Blogs</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['blog-category-list']
            "
          >
            <a routerLink="blog-category-list"
              ><i class="fe fe-document"></i> <span>Blog Category</span></a
            >
          </li>
          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['contact-form-list']
            "
          >
            <a routerLink="contact-form-list"
              ><i class="far fa-file-alt"></i> <span>Contact Form List</span></a
            >
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="rolePermissionData && rolePermissionData['faq-list']"
          >
            <a routerLink="faq-list"
              ><i class="far fa-question-circle"></i> <span>FAQS</span></a
            >
          </li>

          <li
            routerLinkActive="active current"
            *ngIf="
              rolePermissionData && rolePermissionData['session-feedback-list']
            "
          >
            <a routerLink="session-feedback-list"
              ><i class="far fa-file-alt"></i> <span>Session Feedbacks</span></a
            >
          </li>

          <li
            class="dropdown-list"
            [ngClass]="{ active: dropdownClicked }"
            ngbDropdown
            *ngIf="hasDropdownPermissionsForReport()"
          >
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="efg"
              ngbDropdownToggle
            >
              <i class="far fa-file"></i> <span>All Reports</span>
            </a>

            <div ngbDropdownMenu="efg" (click)="closeDropdown()">
              <a
                *ngIf="
                  rolePermissionData && rolePermissionData['counsellor-list']
                "
                routerLink="therapist-report"
              >
                <i class="fas fa-file-alt"></i> Therapist
              </a>
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['therapy-seeker-list']
                "
                routerLink="therapy-seeker-report"
              >
                <i class="fas fa-file-alt"></i> therapy Seeker
              </a>
              <a
                *ngIf="
                  rolePermissionData && rolePermissionData['appointment-list']
                "
                routerLink="appointments-report"
              >
                <i class="far fa-file-alt"></i> Appointments
              </a>
              <a
                *ngIf="
                  rolePermissionData && rolePermissionData['order-history-list']
                "
                routerLink="order-history-report"
              >
                <i class="far fa-file-alt"></i> Order Histoty
              </a>
              <a
                *ngIf="
                  rolePermissionData && rolePermissionData['gift-session-list']
                "
                routerLink="gift-session-report"
              >
                <i class="far fa-file-alt"></i> Gift Session
              </a>
              <a
                *ngIf="
                  rolePermissionData &&
                  rolePermissionData['assessment-results-list']
                "
                routerLink="assessment-list-report"
              >
                <i class="far fa-file-alt"></i> Assessment Results
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- /Sidebar -->
