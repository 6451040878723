import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doctor-navbar',
  templateUrl: './doctor-navbar.component.html',
  styleUrls: ['./doctor-navbar.component.css'],
})
export class DoctorNavbarComponent implements OnInit {
  link: any;
  isLoggedIn: boolean = false;
  gender: string;
  genderName: string;
  currentUser: any;
  imagePath: any;
  isCollapsed: boolean = false;
  constructor(
    private authService: AuthService,
    private service: UserService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.currentUser = this.authService.currentUser;
    this.gender = this.currentUser.gender;
    this.link = `schedule-timing/${this.authService.currentUser.user_id}`;

    this.gender === 'M'
      ? (this.genderName = 'Male')
      : (this.genderName = 'Female');
    this.imagePath =
      environment.imagePath +
      `default/default-profile-pic-${this.genderName}.jpg`;
  }
  ngOnInit(): void {
    this.authService.getLoggedInfo.subscribe((logindata: any) => {
      console.log(logindata, '-------FFFFFF------');
      this.currentUser = logindata;
    });
    this.isLoggedIn = this.authService.isLoggedIn();
    if (this.isLoggedIn) {
      let currentUser = this.authService.currentUser;
      console.log(this.authService.currentUser, '-------USER---');
    }
    // this.service.getUserProfile(this.doctor_id).subscribe(
    //   (data: any) => {
    //     this.doctor = data;
    //     console.log(this.doctor.profile.practicing_from.split('-')[0], 2222);
    //     this.YOE =
    //       this.date - this.doctor.profile.practicing_from.split('-')[0];
    //     this.specialization = data?.profile?.specializations?.split(',') ?? [];
    //     this.approach = data?.profile?.approach_to_care?.split(',') ?? [];
    //     this.affiliation = data?.profile?.affiliations?.split(',') ?? [];
    //     this.language = data?.profile?.language?.split(',') ?? [];
    //     data.basic.title === 'Mr'
    //       ? (this.gender = 'He')
    //       : (this.gender = 'She');
    //     this.profileRating = data.profile.star_rating;
    //   },

    //   (error: any) => {
    //     this.toastr.error('', error);
    //   }
    // );
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    document.querySelector('.desh_main')?.classList.toggle('active');
    document.getElementsByTagName('body')[0].classList.toggle('overlay');
  }
}
