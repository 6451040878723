import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() getLoggedInfo: EventEmitter<any> = new EventEmitter();
  private baseurl = '';
  authState: any;
  constructor(private http: HttpClient) {
    this.baseurl = environment.api_baseUrl;
  }

  getAuthenticationToken() {
    let myRawToken = localStorage.getItem('token') as string;
    if (myRawToken != null) {
      return myRawToken;
    }

    myRawToken = sessionStorage.getItem('token') as string;
    if (myRawToken != null) {
      return myRawToken;
    }

    return null;
  }

  setAuthenticationToken(tonken: string, rememberMe: boolean) {
    if (rememberMe) {
      localStorage.setItem('token', tonken);
    } else if (rememberMe == false) {
      localStorage.setItem('token', tonken);
    } else if (window.sessionStorage) {
      sessionStorage.setItem('token', tonken);
    } else {
      localStorage.setItem('token', tonken);
    }
  }

  updateAuthenticationToken(newTonken: string) {
    let myRawToken = localStorage.getItem('token') as string;
    if (myRawToken != null) {
      localStorage.setItem('token', newTonken);
      return;
    }

    myRawToken = sessionStorage.getItem('token') as string;
    if (myRawToken != null) {
      sessionStorage.setItem('token', myRawToken);
      return;
    }
  }

  captchValidation(captchToken: string) {
    let requestPostObj: any = {};
    requestPostObj.recaptcha = captchToken;

    return this.http
      .post(
        this.baseurl + '/api/captcha-verify',
        JSON.stringify(requestPostObj)
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(result);
          return result;
        })
      );
  }

  getCaptcha() {
    return this.http.get(this.baseurl + '/api/captcha-generate').pipe(
      catchError((error: any) => {
        console.log(error);
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(result);
        return result;
      })
    );
  }

  adminLogin(credentials: any) {
    return this.http
      .post(this.baseurl + '/api/login', JSON.stringify(credentials))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          let helper = new JwtHelperService();
          let userdetail = helper.decodeToken(result.token).data;
          localStorage.setItem('userId', userdetail.user_id);
          localStorage.setItem('userTypeId', userdetail.user_type_id);
          if (userdetail.user_type_id == 7 || userdetail.user_type_id == 1) {
            this.setAuthenticationToken(result.token, credentials.rememberMe);
            this.getLoggedInfo.emit(userdetail);
            return userdetail;
          } else {
            return null;
          }
        })
      );
  }

  login(credentials: any) {
    console.log(credentials, '-----CREDENTIALS-------');

    return this.http
      .post(this.baseurl + '/api/login', JSON.stringify(credentials))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          this.setAuthenticationToken(result.token, credentials.rememberMe);
          console.log(credentials.rememberMe);

          let helper = new JwtHelperService();
          let userdetail = helper.decodeToken(result.token).data;
          localStorage.setItem('userId', userdetail.user_id);
          localStorage.setItem('firstName', userdetail.first_name);
          localStorage.setItem('lastName', userdetail.last_name);
          localStorage.setItem('email', userdetail.email);
          localStorage.setItem('userTypeId', userdetail.user_type_id);
          this.getLoggedInfo.emit(userdetail);
          return userdetail;
        })
      );
  }

  register(formdata: any) {
    return this.http
      .post(this.baseurl + '/api/register', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }

  logout() {
    let myRawToken = localStorage.getItem('token') as string;
    if (myRawToken != null) {
      localStorage.removeItem('token');
    }
    let userId = localStorage.getItem('userId');
    if (userId) {
      localStorage.removeItem('userId');
    }

    myRawToken = sessionStorage.getItem('token') as string;
    if (myRawToken != null) {
      sessionStorage.removeItem('token');
    }
    this.getLoggedInfo.emit(null);

    localStorage.clear();
  }

  isLoggedIn() {
    let helper = new JwtHelperService();
    let myRawToken = this.getAuthenticationToken();
    if (myRawToken == null) {
      return false;
    }

    let decodedToken = helper.decodeToken(myRawToken);
    let expirationDate = helper.getTokenExpirationDate(myRawToken);
    let isExpired = helper.isTokenExpired(myRawToken);
    return !isExpired;
  }

  get currentUser() {
    let helper = new JwtHelperService();
    let myRawToken = this.getAuthenticationToken();
    if (myRawToken == null) {
      return null;
    }

    let userdetail = helper.decodeToken(myRawToken).data;
    return userdetail;
  }

  updateProfilePic(profile_img: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    let formdata = {
      profile_img: profile_img,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-profile-pic/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }
  updateVideo(video_file: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    let formdata = {
      video_file: video_file,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-profile-info-doctor-new/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  updateUserBasicInfo(formdata: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-profile-info-patient-new/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  updateDoctorBasicInfo(formdata: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-profile-info-doctor-new/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  updateUserProfileInfo(formdata: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-profile-info/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  updateUserAddressInfo(formdata: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-address-info/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  updateUserSpecilizationServiceInfo(formdata: string, user_id: number) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-specilization-service/' + user_id,
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          if (user_id == this.currentUser.user_id) {
            this.updateAuthenticationToken(result.token);
            let helper = new JwtHelperService();
            let userdetail = helper.decodeToken(result.token).data;
            this.getLoggedInfo.emit(userdetail);
          }
          return true;
        })
      );
  }

  changePassword(formdata: string) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/change-password',
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }

  forgotPassword(formdata: string) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/forgot-password',
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  updatePassword(formdata: string) {
    let authToken = this.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/update-forgot-password',
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
}
