import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import {
  AppointmentState,
  appointmentReducer,
} from './reducers/appointment.reducers';

export type AppointmentStore = {
  appointment: AppointmentState;
};

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('appointment', appointmentReducer),
  ],
})
export class AppointmentStoreModule {}
