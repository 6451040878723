import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { DoctorLayoutComponent } from './layouts/doctor-layout/doctor-layout.component';
import { PatientLayoutComponent } from './layouts/patient-layout/patient-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ZoomLayoutComponent } from './layouts/zoom-layout/zoom-layout.component';

import { ConnectFormComponent } from './connect-form/connect-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { UpdatePasswordComponent } from './update-password/update-password.component';

const ADMIN_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
];

const DOCTOR_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./doctor/doctor.module').then((m) => m.DoctorModule),
  },
];

const PATIENT_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./patient/patient.module').then((m) => m.PatientModule),
  },
];

const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
];

const ZOOM_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./zoom/zoom.module').then((m) => m.ZoomModule),
  },
];

const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES },

  { path: 'connect-form', component: ConnectFormComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'update-password', component: UpdatePasswordComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'admin/login', component: AdminLoginComponent },
  { path: 'no-access', component: NoAccessComponent },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: ADMIN_ROUTES,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'therapy-seeker',
    component: PatientLayoutComponent,
    children: PATIENT_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: 'therapist',
    component: DoctorLayoutComponent,
    children: DOCTOR_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: 'zoom',
    component: ZoomLayoutComponent,
    children: ZOOM_ROUTES,
    canActivate: [AuthGuard],
  },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
