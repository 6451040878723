import { createAction, props } from '@ngrx/store';

export enum AppointmentAction {
  SAVE_DOCTOR_ID = 'SAVE_DOCTOR_ID',
  SAVE_REDIRECT_URL = 'SAVE_REDIRECT_URL',
}

export const bookedDoctorId = createAction(
  AppointmentAction.SAVE_DOCTOR_ID,
  props<{ doctorId: number }>()
);
export const setRedirectUrl = createAction(
  AppointmentAction.SAVE_REDIRECT_URL,
  props<{ redirectUrl: string }>()
);
