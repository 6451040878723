import { Component, OnInit } from '@angular/core';
import { ZoomMtg } from '@zoomus/websdk';

@Component({
  selector: 'app-zoom-layout',
  templateUrl: './zoom-layout.component.html',
  styleUrls: ['./zoom-layout.component.css'],
})
export class ZoomLayoutComponent implements OnInit {
  constructor() {
    // ZoomMtg.setZoomJSLib(
    //   'https://devteamworks.in/careocure/node_modules/@zoomus/websdk/dist/lib',
    //   '/av'
    // );
    // ZoomMtg.preLoadWasm();
    // ZoomMtg.prepareWebSDK();
  }

  ngOnInit(): void {
    // ZoomMtg.setZoomJSLib(
    //   'https://devteamworks.in/careocure/node_modules/@zoomus/websdk/dist/lib',
    //   '/av'
    // );
    // ZoomMtg.preLoadWasm();
    // ZoomMtg.prepareWebSDK();
  }
}
