import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminHeaderComponent implements OnInit {
  isLoggedIn: boolean = false;
  loggedUserName: string = '';
  isAdmin: boolean = false;
  closeResult: string;
  userTypeId: number = 0;

  currentUser: any;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.currentUser = this.authService.currentUser;
    this.closeResult = '';
    this.isLoggedIn = this.authService.isLoggedIn();

    if (this.isLoggedIn) {
      let currentUser = this.authService.currentUser;

      this.userTypeId = currentUser.user_type_id;
      this.loggedUserName =
        currentUser.first_name + ' ' + currentUser.last_name;
    }
  }

  ngOnInit(): void {
    //   this.authService.getLoggedInfo.subscribe((logindata:any)=>{
    //     this.currentUser = logindata;

    //   });
    // }

    this.authService.getLoggedInfo.subscribe((logindata: any) => {
      this.isLoggedIn = this.authService.isLoggedIn();
      console.log('Loggin Flag::' + this.isLoggedIn);

      if (this.isLoggedIn) {
        this.loggedUserName = logindata.first_name + ' ' + logindata.last_name;
        if (logindata.user_type_id == 1) this.isAdmin = true;
        else this.isAdmin = false;
      } else {
        this.loggedUserName = '';
        this.isAdmin = false;
      }
    });
  }

  MenuOpenClose() {
    const mobileSidePnal = document
      .getElementById('sidebar')
      ?.classList.toggle('active');
  }
}
