import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, delay, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private baseurl = '';
  constructor(private http: HttpClient, private auth: AuthService) {
    this.baseurl = environment.api_baseUrl;
  }

  getAdminDashboardCount() {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/admin-dashboard-count').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  changeUserStatus(userId: number, status: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/change-user-status/' + userId + '/' + status)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  makeRefundRequest(appointmentId: number, status?: string) {
    return this.http
      .get(
        this.baseurl +
          '/api/cancelled-appointment-update/' +
          appointmentId +
          `?refund=${status}`
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  contactStatus(Id: number, payload) {
    return this.http
      .post(this.baseurl + '/api/contact-form-status-update/' + Id, {
        status: payload,
      })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getAdminDoctors(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-doctors',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getAdminTherapyList() {
    return this.http.get(this.baseurl + '/api/admin-doctor-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  getAdminTherapyseekerList() {
    return this.http.get(this.baseurl + '/api/admin-patient-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  getAdminPatients(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-patients',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getAdminPatientsAssessment() {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.post(this.baseurl + '/api/admin-patients', options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  getUsers(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };
    return this.http
      .post(this.baseurl + '/api/user-list', JSON.stringify(payload), options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getQuestionCategory(dataTablesParameters?: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.get(this.baseurl + '/api/course-category-list').pipe(
      catchError((error: any) => {
        console.log(error.error, '=======>');
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  getAssessmentList(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post(
        this.baseurl + '/api/assesments-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error, '=======>');
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  getTherapistAssessmentList(doctor_id: number) {
    const url = `${this.baseurl}/api/doctor-shared-assignment-list/${doctor_id}`;
    const requestBody = {
      doctor_id: doctor_id,
    };

    return this.http.post(url, requestBody).pipe(
      catchError((error: any) => {
        console.log(error.error, '=======>');
        return throwError(error.error.messages);
      }),
      map((response: any) => {
        // Assuming you want to return share_document array
        return response.share_document;
      })
    );
  }

  sendReport(formdata: any, doctor_id: number) {
    // Construct the URL with the doctor_id included
    const url = `${this.baseurl}/api/doctor-share/${doctor_id}`;
    return this.http.post(url, JSON.stringify(formdata)).pipe(
      catchError((error: any) => {
        console.log(error.error.messages);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(formdata);
        return result;
      })
    );
  }

  getAdminAssessmentList(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post(
        this.baseurl + '/api/assesments-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error, '=======>');
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getQuestionCategoryEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/course-category-edit/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error.error, '=======>');
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(result);
          return result;
        })
      );
  }

  getQuestionSheet(dataTablesParameters: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.get(this.baseurl + '/api/questionsheet-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  getAsessmentQuestions(user_id: string): Observable<any[]> {
    const params = new HttpParams().set('user_id', user_id);

    return this.http
      .get<any[]>(this.baseurl + '/api/questionsheet-list', { params: params })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  getPaidAsessmentQuestions(user_id: string): Observable<any[]> {
    const params = new HttpParams().set('user_id', user_id);

    return this.http
      .get<any[]>(this.baseurl + '/api/questionsheet-list', { params: params })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.purchase_assessments;
        })
      );
  }
  getAdminAppointments(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-appointments',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getAdminCancelAppointments(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-cancel-appointments',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  getOrderReport(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-appointments-export',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  downloadExcel(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(
      this.baseurl + '/api/admin-appointments-export',
      payload,
      {
        headers: headers,
        responseType: 'blob', // responseType is set to Blob to indicate file download
      }
    );
  }
  downloadExcelForAppointment(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(
      this.baseurl + '/api/admin-all-appointments-export',
      payload,
      {
        headers: headers,
        responseType: 'blob', // responseType is set to Blob to indicate file download
      }
    );
  }

  downloadExcelForGift(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(this.baseurl + '/api/gift-list-export', payload, {
      headers: headers,
      responseType: 'blob', // responseType is set to Blob to indicate file download
    });
  }
  downloadExcelForDocReport(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(this.baseurl + '/api/admin-doctors-export', payload, {
      headers: headers,
      responseType: 'blob', // responseType is set to Blob to indicate file download
    });
  }
  downloadExcelForPatientReport(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(
      this.baseurl + '/api/admin-patients-export',
      payload,
      {
        headers: headers,
        responseType: 'blob', // responseType is set to Blob to indicate file download
      }
    );
  }
  downloadExcelForAssessmentReport(payload: any): Observable<Blob> {
    let authToken = this.auth.getAuthenticationToken(); // Assuming this.auth exists
    let headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the MIME type for Excel files
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.post(
      this.baseurl + '/api/assesments-list-export',
      payload,
      {
        headers: headers,
        responseType: 'blob', // responseType is set to Blob to indicate file download
      }
    );
  }
  submitQuestionn(payload?: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/question-store',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  submitCategory(payload?: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/course-category-add',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  submitFormStepOne(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/questionsheet-store',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  updateFormStepOne(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/questionsheet-update/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  updateFormStepTwo(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/questionsheet-update-part-two/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  submitFormStepTwo(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/questionsheet-store-part-two',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteQuestionSheet(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/questionsheet-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteCategory(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/course-category-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteSheet(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let payload = {
      assesment_id: id,
    };

    return this.http
      .post(this.baseurl + '/api/assesments-mid-way-remove/', payload)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteQuestion(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/question-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteAdminUser(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/user-delete/' + id, options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  deleteAdminRole(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/role-delete/' + id, options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  deleteTestimonial(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/testimonials-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  questionSheetEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/questionsheet-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  assessmentDetails(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/assesments-question-answer/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data;
        })
      );
  }

  feedbackDetails() {
    return this.http.get(this.baseurl + '/api/session-feedback-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  questionSheetEditSecond(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/questionsheet-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.sheet_question_data;
      })
    );
  }

  getAdminTodayAppointments(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/admin-today-appointments',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getCmsPages(dataTablesParameters: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/cms-page-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(result.data[0].id);
        return result;
      })
    );
  }

  getCmsPagesDetails(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/cms-page-details-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(result, 111111);
          return result;
        })
      );
  }

  getCategoryWiseQuestions(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };
    return this.http
      .get(this.baseurl + '/api/category-wise-question/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(result.data);
          return result;
        })
      );
  }
  getAppointmentDetails(id: number) {
    return this.http
      .get(this.baseurl + '/api/book-appointment-details/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.appointment;
        })
      );
  }

  editCmsPage(dataTablesParameters: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/edit-cms-page-block/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(result.data[0].id);
        return result;
      })
    );
  }

  updateCmsPage(formdata: string, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/edit-cms-page-block-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }

  getAdminGiftSessions(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/gift-list', JSON.stringify(payload), options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  getAdminRoleList() {
    return this.http.get(this.baseurl + '/api/role-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  getRolePermissionList() {
    return this.http.get(this.baseurl + '/api/permission-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }
  getGiftSessions(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };
    return this.http
      .post(this.baseurl + '/api/gift-list', JSON.stringify(payload), options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data.filter((item: any) => parseInt(item.status) === 1);
        })
      );
  }

  getContacts(dataTablesParameters: any) {
    return this.http.get(this.baseurl + '/api/contact-form-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  getUserFeedbacks() {
    return this.http.get(this.baseurl + '/api/session-feedback-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  adminGetFaqs() {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/faq-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  getFaqs(): Observable<any[]> {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http.get<any[]>(this.baseurl + '/api/faq-list', options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data.filter(
          (item: any) => parseInt(item.status, 10) === 1
        );
      })
    );
  }
  editFaq(dataTablesParameters: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/faq-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  getQuestions(dataTablesParameters: any, payload: any = {}) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/question-list', { params: payload })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getMoodReport(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/overall-mood-report/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  editQuestions(id: number): Observable<any[]> {
    return this.http
      .get<any[]>(this.baseurl + '/api/questionsheet-edit/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.sheet_question_data;
        })
      );
  }

  editGiftSession(dataTablesParameters: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/gift-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(result.data[0].id);
        return result;
      })
    );
  }

  editRole(id: number) {
    return this.http.get(this.baseurl + '/api/role-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  editQuestionSheet(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/questionsheet-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        console.log(result.data[0].id);
        return result;
      })
    );
  }

  editQuestion(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/question-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  updateQuestion(formdata: string, id: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/question-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }
  updateGiftSession(formdata: string, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/gift-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }
  updateFaq(formdata: string, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/faq-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }

  updateCategory(formdata: string, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/course-category-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }
  addSession(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/gift-add', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  addCoupon(formdata: any) {
    return this.http
      .post(this.baseurl + '/api/cupon-store', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }

  addTestimonial(formdata: any) {
    return this.http
      .post(this.baseurl + '/api/testimonials-store', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  editTestimonial(formdata: any, id: number) {
    return this.http
      .post(
        this.baseurl + '/api/testimonials-update/' + id,
        JSON.stringify(formdata)
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  addRolePermission(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/role-create',
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  editRolePermission(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/role-update',
        JSON.stringify(formdata),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }

  addMessage(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/contact-form-store', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  addFAQ(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/faq-add', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return true;
        })
      );
  }
  addGift(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/gift-purchase', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return result;
        })
      );
  }

  verifyPayment(rzpPaymentId?: string) {
    return this.http
      .get(this.baseurl + `/gift-payment-razorpay/${rzpPaymentId}`)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  cancelPayment(bookingId?: string) {
    return this.http
      .get(this.baseurl + `/cancel-gift-razorpay/${bookingId}`)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  uploadTestimonial(videoToUpload: File) {
    const endpoint = this.baseurl + '/api/uploadalltypevideo';
    const formData: FormData = new FormData();
    formData.append('vid_file', videoToUpload, videoToUpload.name);

    return this.http.post(endpoint, formData).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages.error);
      }),
      map((result: any) => {
        console.log(result);
        return result.path;
      })
    );
  }
  excelExport(fileToUpload: File) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/export', fileToUpload, options)
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          // console.log(formdata);
          return true;
        })
      );
  }

  // excelExport(fileToUpload: File) {
  //   const endpoint = this.baseurl + '/api/export';
  //   const formData: FormData = new FormData();
  //   formData.append('file', fileToUpload);

  //   return this.http.post(endpoint, formData).pipe(
  //     catchError((error: any) => {
  //       console.log(error);
  //       return throwError(error.error.messages.error);
  //     }),
  //     map((result: any) => {
  //       console.log(result);

  //       return result;
  //     })
  //   );
  // }
  encryptdata(request) {
    // let url = `${this.baseurl}/orders/encryptFormData`;
    let url = this.baseurl + '/api/gift-list';
    let data = {
      request: request,
    };
    return this.http.get(url, { params: data });

    // return this.http.get(url);
  }

  // Start Severty

  getSeverty(dataTablesParameters: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    // let payload = {
    //   draw: dataTablesParameters.draw,
    //   start: dataTablesParameters.start,
    //   length: dataTablesParameters.length,
    //   search: dataTablesParameters.search.value,
    // };

    return this.http.get(this.baseurl + '/api/severty-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        //console.log(result.severty_data);
        return result;
      })
    );
  }

  addSeverty(formdata: any) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/severty-add', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata);
          return result;
        })
      );
  }
  addAssessment(formdata: any) {
    return this.http
      .post(this.baseurl + '/api/assesments-store', JSON.stringify(formdata))
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          console.log(formdata, 12345);
          return result;
        })
      );
  }

  editSeverty(dataTablesParameters: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/severty-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        // console.log(result.data[0].id);
        return result;
      })
    );
  }
  updateSeverty(formdata: string, id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/severty-update/' + id,
        JSON.stringify(formdata),
        options
      )

      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return true;
        })
      );
  }
  deleteSeverty(id: number) {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ',
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/severty-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error.error.messages);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  //blog
  getBlogs(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(this.baseurl + '/api/post-list', JSON.stringify(payload), options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getBlogCategoriesAdmin(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/post-category-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  getBlogCategories() {
    return this.http.get(this.baseurl + '/api/post-category-active-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  getAllBlogs(catId: string) {
    return this.http
      .post(this.baseurl + '/api/frontend-blog-list', { cat_id: catId })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data;
        })
      );
  }

  getAllCategories() {
    return this.http.get(this.baseurl + '/api/post-category-active-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  getAllBlogDetails(id: string) {
    return this.http
      .get(this.baseurl + '/api/frontend-blog-slug-details/' + id)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result.data;
        })
      );
  }

  blogAddFormSubmit(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(this.baseurl + '/api/post-store', JSON.stringify(payload), options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  blogCategoryAddFormSubmit(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/post-category-store',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  blogEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/post-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  blogCategoryEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/post-category-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  blogUpdateFormSubmit(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/post-update/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  blogCategoryUpdateFormSubmit(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/post-category-update/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
  deleteBlog(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/post-delete/' + id, options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  deleteBlogCategory(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .get(this.baseurl + '/api/post-category-delete/' + id, options)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  deleteGiftSession(id: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/gift-delete/' + id, options).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  //Coupon
  adminGetCupons(dataTablesParameters: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    return this.http.get(this.baseurl + '/api/cupon-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  //Testimonials

  adminGetTestimonials(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/testimonials-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  //gift purchased list
  adminGetGiftPurchasedList(payload: any) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });
    let options = {
      headers: headers,
    };

    return this.http
      .post(
        this.baseurl + '/api/gift-share-list',
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  adminMoodReportList(dataTablesParameters: any) {
    return this.http.get(this.baseurl + '/api/mood-list').pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result.data;
      })
    );
  }

  couponCodeEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/cupon-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }
  testimonialEdit(id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    return this.http.get(this.baseurl + '/api/testimonials-edit/' + id).pipe(
      catchError((error: any) => {
        console.log(error);
        return throwError(error.error.messages);
      }),
      map((result: any) => {
        return result;
      })
    );
  }

  CouponCodeUpdateFormSubmit(payload: any, id: number) {
    let authToken = this.auth.getAuthenticationToken();
    let headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + authToken,
    });

    let options = {
      headers: headers,
    };

    console.log(payload, '=========>>>>>');

    return this.http
      .post(
        this.baseurl + '/api/cupon-update/' + id,
        JSON.stringify(payload),
        options
      )
      .pipe(
        catchError((error: any) => {
          console.log(error);
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  cancelPaymentForAssessment(bookingId?: string) {
    return this.http
      .get(this.baseurl + `/cancel-assessment-razorpay/${bookingId}`)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  //assessment purchase
  bookAssessment(data: any) {
    console.log(data);

    return this.http
      .post(
        this.baseurl + '/api/questionsheet-purchase-store',
        JSON.stringify(data)
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }

  verifyPaymentForAssessment(rzpPaymentId?: string) {
    return this.http
      .get(this.baseurl + `/assessment-payment-razorpay/${rzpPaymentId}`)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error.messages);
        }),
        map((result: any) => {
          return result;
        })
      );
  }
}
