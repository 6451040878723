<!-- Header -->
<div class="header admin_header">
  <div class="container-fluid">
    <div class="admin_hdr">
      <!-- Logo -->
      <div class="header-left">
        <a routerLink="/home" class="logo">
          <img src="assets/images/logo.png" alt="Logo" />
        </a>
        <a routerLink="/" class="logo logo-small">
          <img
            src="assets/images/small-logo.png"
            alt="Logo"
            width="30"
            height="30"
          />
        </a>
      </div>

      <!-- Mobile Menu Toggle -->
      <a class="mobile_btn" (click)="MenuOpenClose()">
        <i class="fa fa-bars"></i>
      </a>
      <!-- /Mobile Menu Toggle -->

      <!-- Header Right Menu -->
      <ul class="nav user-menu">
        <!-- User Menu -->
        <li class="nav-item dropdown has-arrow">
          <div ngbDropdown>
            <button class="btn" type="button" id="gfg" ngbDropdownToggle>
              <a href="javascript:void(0);">
                <span class="user-img"
                  ><img
                    class="rounded-circle"
                    src="{{ currentUser.profile_img }}"
                    width="31"
                    alt="{{ currentUser.first_name }}"
                /></span>
              </a>
            </button>
            <div ngbDropdownMenu="gfg">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img
                    src="{{ currentUser.profile_img }}"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="user-text">
                  <h6>
                    {{ currentUser.first_name + " " + currentUser.last_name }}
                  </h6>
                  <p class="text-muted mb-0">Administrator</p>
                </div>
              </div>
              <li>
                <a class="dropdown-item" routerLink="home" ngbDropdownItem
                  >My Dashboard</a
                >
              </li>
              <li>
                <a routerLink="/logout" ngbDropdownItem>Logout</a>
              </li>
            </div>
          </div>
        </li>
        <!-- /User Menu -->
      </ul>
      <!-- /Header Right Menu -->
    </div>
  </div>
</div>

<!-- /Header -->
