import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css'],
})
export class AdminNavbarComponent implements OnInit {
  @ViewChild(NgbDropdown) dropdown: NgbDropdown | undefined;
  dropdownClicked: boolean = false;
  id: any;
  rolePermissionData: any;
  permissions: any[] = [];
  constructor(private adminService: AdminService) {
    this.id = localStorage.getItem('userTypeId');
  }

  ngOnInit(): void {
    this.adminService.editRole(this.id).subscribe(
      (data: any) => {
        this.rolePermissionData =
          data.length > 0 ? JSON.parse(data[0].permissions) : [];
        // this.getPermissionListDetails()
        console.log(this.rolePermissionData, '------DATA------');
      },
      (error: any) => {}
    );
    this.adminService.editRole(this.id).subscribe(
      (data: any) => {
        this.rolePermissionData =
          data.length > 0 ? JSON.parse(data[0].permissions) : [];
        localStorage.setItem(
          'Permissions',
          JSON.stringify(this.rolePermissionData)
        );
      },
      (error: any) => {}
    );
  }
  hasDropdownPermissions(): boolean {
    return (
      this.rolePermissionData &&
      (this.rolePermissionData['severity-master-list'] ||
        this.rolePermissionData['question-category-list'] ||
        this.rolePermissionData['question-list'] ||
        this.rolePermissionData['assessment-sheet-list'] ||
        this.rolePermissionData['assessment-results-list'])
    );
  }

  hasDropdownPermissionsForReport(): boolean {
    return (
      this.rolePermissionData &&
      (this.rolePermissionData['counsellor-list'] ||
        this.rolePermissionData['therapy-seeker-list'] ||
        this.rolePermissionData['appointment-list'] ||
        this.rolePermissionData['order-history-list'] ||
        this.rolePermissionData['assessment-results-list'] ||
        this.rolePermissionData['gift-session-list'])
    );
  }

  closeDropdown() {
    if (this.dropdown) {
      this.dropdown.close();
      this.dropdownClicked = false; // Set dropdownClicked to true when dropdown is closed
    }
  }
}
