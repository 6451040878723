import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {
    console.log('logout');
    let currentUser = this.authService.currentUser;
    if (currentUser.user_type_id == 1) {
      // @ts-ignore
      google.accounts.id.cancel((data) => {
        console.log(
          data.getNotDisplayedReason(),
          data.isDisplayed(),
          data.isNotDisplayed(),
          'Hi one tap'
        );
      });
      authService.logout();
      this.router.navigate(['/home']);
    } else {
      authService.logout();
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {}
}
