import { createReducer, on } from '@ngrx/store';
import { bookedDoctorId, setRedirectUrl } from '../actions/appointment.actions';

export interface AppointmentState {
  doctor_id?: number;
  redirectUrl?: string;
}

export const initialState: AppointmentState = {
  doctor_id: undefined,
  redirectUrl: undefined,
};

export type BookDoctorPayload = {
  payload: string;
};

export const appointmentReducer = createReducer(
  initialState,
  on(bookedDoctorId, (state, data: any) => {
    return {
      ...state,
      doctor_id: data.doctorId,
    };
  }),
  on(setRedirectUrl, (state, data: any) => {
    return {
      ...state,
      redirectUrl: data.redirectUrl,
    };
  })
);
