import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-doctor-header',
  templateUrl: './doctor-header.component.html',
  styleUrls: ['./doctor-header.component.css'],
})
export class DoctorHeaderComponent implements OnInit {
  isLoggedIn: boolean = false;
  loggedUserName: string = '';
  isAdmin: boolean = false;
  closeResult: string;
  userTypeId: number = 0;
  userType: any;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.closeResult = '';
    this.isLoggedIn = this.authService.isLoggedIn();

    if (this.isLoggedIn) {
      let currentUser = this.authService.currentUser;
      this.userType = currentUser.role_name;
      console.log(this.authService.currentUser, 3333);

      this.userTypeId = currentUser.user_type_id;
      this.loggedUserName =
        currentUser.first_name + ' ' + currentUser.last_name;
    }
  }

  ngOnInit(): void {
    this.authService.getLoggedInfo.subscribe((logindata: any) => {
      this.isLoggedIn = this.authService.isLoggedIn();
      console.log('Loggin Flag::' + this.isLoggedIn);

      if (this.isLoggedIn) {
        this.loggedUserName = logindata.first_name + ' ' + logindata.last_name;
        if (logindata.role_name == 'admin') this.isAdmin = true;
        else this.isAdmin = false;
      } else {
        this.loggedUserName = '';
        this.isAdmin = false;
      }
    });
  }

  mnenuBtn() {
    document.querySelector('.sidemenu')?.addEventListener('click', () => {
      console.log('ripon');
      document.querySelector('.left_desk')?.classList.add('active_menu');
      document.body.classList.add('overlay');
      // document.getElementsByTagName('html')[0].style.overflow = "visible";
    });
  }
}
