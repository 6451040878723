import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AsyncValidators } from 'src/app/helper/user.validator';
import { AuthService } from 'src/app/services/auth.service';
import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.changePasswordForm = this.formBuilder.group({});
  }

  get email() {
    return this.changePasswordForm.get('email');
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(
          /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/
        ),
      ]),
    });
  }

  changePasword() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    console.log(this.changePasswordForm.value);

    this.spinner.show();
    this.authService.forgotPassword(this.changePasswordForm.value).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.changePasswordForm.reset();
        localStorage.setItem('emailbase64', data.url.split('=')[1]);
        this.toastr.success(
          '',
          'A password reset link has been sent to your email. Please check your inbox.'
        );
        this.changePasswordForm.reset();
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error('', error);
        this.changePasswordForm.reset();
      }
    );
  }
}
